import { LinkedinFilled, YoutubeFilled } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../containers/Language";
import { commonConstants } from "../../helper/Common/CommonConstants";

const ModalBody = styled.div`
  h3{
    display: block;
    color: #fff;
    font-size: 24px;
    padding-bottom: 20px;
  }
  .ant-modal-content{
    border-radius: 8px;
  }
`;

const FooterSection = styled.section`
  h3,
  .ant-col.ant-col-xs-24.ant-col-md-12.ant-col-lg-12{
    color: #c4c4c4;
  }
  span{
    line-height:24px;
  }
  .logo-wrap {
    padding:32px 0 28px;
  }
  .icons-list{
      display: flex;
      flex-wrap: nowrap;
      align-content: flex-start;
      align-items: flex-start;
      justify-content: flex-end;
    a, span {
      margin: 0 24px 0 0!important;
    }
  }
  .icons-list span:last-child{
    margin: 0 0 0 0!important;
  }
  .left-side {
    padding-bottom:28px;
  }
  .text-capitalize {
    text-transform: none !important;
  }
  @media (max-width: 820px){
      padding-top: 20px!important;
      padding-bottom: 16px!important;
    .container {
      hr {
        margin: 16px 0;
      }
      .links {
        margin-bottom: 36px;
      }
    }
    .icons-list{
      text-align:left!important;
    }
  }
`;
const FooterModal = styled(Modal)`
width: 680px !important;
max-width: calc(100% - 32px) !important;
.ant-modal-content{
  background:transparent;
  .ant-modal-body {
    padding: 24px 0 24px 24px;
    background-color: #333;
    color: #c4c4c4;
    border-radius: 8px;
    max-height: 100vh!important;
    > div{
      padding-right: 24px;
      max-height: 70vh!important;
      overflow: hidden;
      overflow-y: auto;
    }
    h3 {
      color: #c4c4c4;
    }
  }
  .ant-modal-close-x {
    width: 62px;
    height: 62px;
    line-height: 70px;
    .ant-modal-close-icon svg{
      fill: #c4c4c4!important;
    }
  }
} 
`;
export const DataCommonsMainFooter: React.FC<{}> = ({ }) => {
  const openWindow = (url) => {
    window.open(url);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <FooterSection className='rc-footer-section'>
      <div className='container'>
        <Row className="left-side">
          <Col xs={24} md={12} lg={12}>
            <h3>About</h3>
            <hr />
            <span>UN Data integrates authoritative data from across the UN system into a user-friendly public repository with advanced natural language search functionality. Part of an ongoing effort by the United Nations Statistics Division, supported by Google's Data Commons and funded by Google.org, this tool aims to make authoritative UN data more accessible to the public.</span>
            <div className="logo-wrap">
              <img src='./images/datacommons/UN_logo3.svg' alt='unlogo' />
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className='icons-list'>
              <a target="_blank" style={{ textTransform: 'none' }} href="https://docs.google.com/forms/d/e/1FAIpQLSeAjzArkG-LYDDrdTQ6D1XH1ISWMhlbWE6NRzPt8dYGh_1Ufw/viewform?usp=sf_link">Send us your feedback</a>
              <a href={commonConstants.TWITTER_URL} target="_blank">
                <img height={25} src="./images/datacommons/x-twitter.svg" />
              </a>

              <YoutubeFilled
                onClick={(e) => {
                  openWindow(commonConstants.YOUTUBE_URL);
                }}
              />
              <LinkedinFilled
                onClick={(e) => {
                  openWindow(commonConstants.lINKEDIN_URL);
                }}
              />
            </div>
          </Col>
        </Row>


        <Row gutter={{ xs: 32, sm: 32, md: 32, lg: 32 }}>
          <Col
            className='gutter-row copyright-wrap'
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <a
              href={commonConstants.COPYRIGHT}
              target='_blank'
              rel='noopener noreferrer'
              className='text-capitalize'
            >
              &copy; {new Date().getFullYear()} <Text tid='FooterCopyRight' />
            </a>
            &nbsp;  |  &nbsp;
            <a
              href='https://www.datacommons.org/'
              target='_blank'
              rel='noopener noreferrer'
              className='text-capitalize'
            >
              Powered by Google's Data Commons
            </a>
          </Col>
          <Col
            className='gutter-row'
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <div className='links'>
              <a
                href="javascript: void(0);"
                onClick={showModal}
                rel='noopener noreferrer'
              >
                <Text tid='About' />
              </a>
              <span className='mx-2'>|</span>
              <a
                href="mailto: statistics@un.org"
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text tid='Contact' />
              </a>

              <span className='mx-2'>|</span>
              <a
                href={commonConstants.TERMS_OF_USE}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text tid='FooterTerms' />
              </a>
              <span className='mx-2'>|</span>
              <a
                href={commonConstants.PRIVACY_NOTICE}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text tid='FooterPrivacyNotice' />
              </a>
              <span className='mx-2'>|</span>
              <a
                href={commonConstants.FRAUD_ALERT}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Text tid='FooterFraudAlert' />
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <FooterModal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <ModalBody>
          <h3>About</h3>
          <span>
            The new UN Data platform is designed to modernize and democratize data access and use within the UN system. In collaboration with Google’s Data Commons and funded by Google.org, this platform seamlessly aligns with the UN Secretary-General’s Data Strategy, drawing insights from the Roadmap for Innovating UN Data and Statistics.<br /><br />
            With a focus on visibility, accessibility, and impact, the platform serves as an exhaustive repository for authoritative statistical data and metadata. Equipped with robust search and advanced analytic capabilities, including cutting-edge technologies such as Natural Language Processing and Machine Learning, it addresses the evolving needs of policymakers and decision-makers, fostering interoperability of statistical data across the UN system.<br /><br />
            This platform also extends beyond traditional boundaries, strategically leveraging interlinkages among statistical domains, policy areas, geographies, and various dimensions of data disaggregation, poised to cultivate a more connected and data-informed global community. An increasing number of authoritative datasets will be routinely integrated into the platform as more data partners from the UN system join.
          </span>
        </ModalBody>
      </FooterModal>
    </FooterSection>
  );
}
export default DataCommonsMainFooter;