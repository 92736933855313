import { useCallback, useState } from 'react';
import { Article } from '../templates/DataCommons/components/shared/goals/GoalOverview';
import DataCommonsClient from '../templates/DataCommons/utils/DataCommonsClient';
import { SDG_DEF_YEAR, WEB_API_ENDPOINT_V2 } from '../templates/DataCommons/utils/constants';

interface ArticleNode {
    dcid: string;
    name: string;
  }
  
export interface ArticleResponse {
    [key: string]: {
      arcs: {
        about: {
          nodes: ArticleNode[];
        };
      };
    };
  }
export interface Loading {
  loading?: true;
}
export interface GroupedArticles {
    [key: string] : Article[]
}
const useFetchArticles = () => {
  const [topics, setTopics] = useState([]);
  
  const fetchArticles = useCallback(async (rootTopics) => {
    
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT_V2 });

    try {
      // Get all SDGs articles
      const response:ArticleResponse = await client.getArticles({
        nodes: rootTopics,
        property: '<-about'
      });

      const contentDcid = Object.values(response).reduce((a:any, c) => {
        return c?.arcs?.about ? [...a, ...c.arcs.about.nodes.filter(a=>a.dcid.includes(SDG_DEF_YEAR+'/')).map(a => a.dcid)] : a;
      }, []);

      if(Object.keys(contentDcid).length > 0) {
        // Rearrange data
        let contentToText:GroupedArticles = {};
        Object.keys(response).forEach(a => {
          let nodes = response[a]?.arcs?.about.nodes.filter(a=>a.dcid.includes(SDG_DEF_YEAR+'/'));
          nodes&&nodes.forEach(node => {
            contentToText[a] = {
              ...contentToText[a],
              [node.dcid]: { name: node.name }
            };
          });
        });

        // Get SDGs article texts
        const res = await client.getArticles({
          nodes: contentDcid,
          property: '->*'
        });

        const imageDcid = Object.keys(res).sort().map(dcid => {
          return res[dcid].arcs.image?.nodes[0].dcid;
        });

        // Rearrange data
        Object.keys(contentToText).forEach(a => {
          Object.keys(contentToText[a]).forEach(i => {
            let node = res[i].arcs;
            contentToText[a][i] = {
              ...contentToText[a][i],
              text: node.text.nodes[0].value.replace(/^\\n/, ''),
              tags: node.about.nodes,
              source: node.creditText.nodes[0].value,
              sourceUrl: node.url.nodes[0].value,
              createdAt: node.dateCreated?.nodes[0].value,
              imgDcid: node.image?.nodes[0].dcid
            };
          });
        });

        // Get all SDGs Article images
        const r = await client.getArticles({ nodes: imageDcid, property: '->*' });

        // Rearrange data
        Object.keys(contentToText).forEach(a => {
          Object.keys(contentToText[a]).forEach(i => {
            let nodeIndex = contentToText[a][i].imgDcid;
            let node = r[nodeIndex]?.arcs;
            contentToText[a][i] = {
              ...contentToText[a][i],
              image: node?.url?.nodes[0].value,
              // imageSource: node?.name?.nodes[0].value,
              caption: node?.caption?.nodes[0].value,
              credit: node?.creditText?.nodes[0].value,          
              note: node?.note?.nodes[0].value,

            };
          });
        });
      
        //   setTopics(contentToText);
        return contentToText

      } return false;
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, [])

  return { topics, fetchArticles };
}

export default useFetchArticles;

