/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { HomeSection, SectionHeader } from "./components";
// @ts-ignore
import { Carousel, Col, Row } from "antd";
import { routeDataCommonsConstants } from "../../../../../src/helper/Common/RoutePathConstants";
import { BlockTitle } from "./MapSection";

const StyledLayout = styled.div`
  text-align: left;
  background-image: url("./images/datacommons/ta-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 200px;
  padding: 2re 16px;
  > .container {
    padding:64px 16px;
  }
  // @media(min-width:767px){
  //   background-size: contain;
  // }
`;

const Container = styled(HomeSection)`
  text-align: left;
`;

const Header = styled(SectionHeader)`
  width: 100%;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;

   .ant-row {
    border-bottom: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  }
  a {
    font-size: 14px;
    color: #283460;
    display:flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    font-weight: 500;
    white-space: nowrap;
    line-height: 25px;
    align-items: flex-start;
  }
`;

const CarouselSection = styled.div`
  height: 100%;
  width: 100%;
  display: "block";
  padding: 2rem 0;

  .ant-carousel .slick-slider{
    margin:0 -8px;
  }
  .ant-carousel .slick-slider .slick-dots-bottom {
    bottom: -60px;
  }
  .ant-carousel .slick-slider .slick-dots li.slick-active button {
    border: 3px solid #283460;
    background-color: #283460;
  }
  .ant-carousel .slick-slider .slick-dots li button {
    width: 12px;
    height: 12px;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-color: #d9d9d9;
  }
  .ant-carousel .slick-slide img{
    display: unset;
  }
}
`;

const AreaCard = styled.div`
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  margin: 10px;
  span {
    display: block;
    padding: 2rem;
    background-color: #f1f1f1;
    text-align: center;
  }
  h3 {
    background-color: #fff;
    font-size: 20px;
    padding: 20px;
    @media(min-width:992px) and (max-width:1400px){
      min-height:90px;
    }
  }
`;

export const ThematicAreas = () => {
  const history = useHistory();
  const list = [
    {
      section: [
        {
          id: 503827296,
          color: "#509E3E",
          label: "Children and Youth",
          icon: "./images/datacommons/ta-children.svg",
        },
        {
          id: 1471028664,
          color: "#509E3E",
          label: "Health",
          icon: "./images/datacommons/ta-health.svg",
        },
        {
          id: 414038628,
          color: "#509E3E",
          label: "Poverty and Food Security",
          icon: "./images/datacommons/ta-poverty.svg",
        },
      ],
    },
    {
      section: [
        {
          id: 975485693,
          color: "#509E3E",
          label: "Climate and Environment",
          icon: "./images/datacommons/ta-climate.svg",
        },
        {
          id: 548513964,
          color: "#509E3E",
          label: "Equality and Human Rights",
          icon: "./images/datacommons/ta-human-rights.svg",
        },
        {
          id: 646330495,
          color: "#509E3E",
          label: "Urbanization / Human Settlements",
          icon: "./images/datacommons/ta-urbanization.svg",
        },
      ],
    },
    {
      section: [
        {
          id: 53461843,
          color: "#509E3E",
          label: "Water, Sanitation and Hygiene",
          icon: "./images/datacommons/ta-water.svg",
        },
        {
          id: 885633335,
          color: "#509E3E",
          label: "Governance and peace",
          icon: "./images/datacommons/ta-gov.svg",
        },
        {
          id: 1134626659,
          color: "#509E3E",
          label: "Economic Development",
          icon: "./images/datacommons/ta-economy.svg",
        },
      ],
    },
    {
      section: [
        {
          id: 310640129,
          color: "#509E3E",
          label: "Disasters / Humanitarian Action",
          icon: "./images/datacommons/ta-disasters.svg",
        },
        {
          id: 343527823,
          color: "#509E3E",
          label: "Education and Culture",
          icon: "./images/datacommons/ta-education.svg",
        },
        {
          id: 181604978,
          color: "#509E3E",
          label: "Population and Demography",
          icon: "./images/datacommons/ta-population.svg",
        },
      ],
    },
  ];

  return (
    <>
      <StyledLayout>
        <Container className="container">
          <Header>
            <Row>
              <BlockTitle style={{ textAlign: "left" }} xs={20}>
              Thematic Areas
              </BlockTitle>
              {/* <Col style={{ textAlign: "left" }} xs={20}>
                Thematic Areas
              </Col> */}
              <Col style={{ textAlign: "right" }} xs={4}>
                <Link className="-dc-home-thematic-areas-see-all" to={`${routeDataCommonsConstants.THEMATIC_AREAS}`}>
                  See all 
                  <span className="material-icons">
                    chevron_right
                  </span>
                </Link>
              </Col>
            </Row>
          </Header>

          <CarouselSection>
            <Carousel autoplay autoplaySpeed={15000}>
              <div>
                <Row gutter={[32, 32]}>
                  {list[0].section.map((v, i) => {
                    return (
                      <Col key={i} xs={24} lg={8}>
                        <Link className="-dc-thematic-areas-card" to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                          <AreaCard>
                            <span>
                              <img height={100} src={v.icon} />
                            </span>
                            <h3>{v.label}</h3>
                          </AreaCard>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div>
                <Row gutter={[32, 32]}>
                  {list[1].section.map((v, i) => {
                    return (
                      <Col key={i} xs={24} lg={8}>
                        <Link className="-dc-thematic-areas-card" to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                          <AreaCard>
                            <span>
                              <img height={100} src={v.icon} />
                            </span>
                            <h3>{v.label}</h3>
                          </AreaCard>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div>
                <Row gutter={[32, 32]}>
                  {list[2].section.map((v, i) => {
                    return (
                      <Col key={i} xs={24} lg={8}>
                        <Link className="-dc-thematic-areas-card" to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                          <AreaCard>
                            <span>
                              <img height={100} src={v.icon} />
                            </span>
                            <h3>{v.label}</h3>
                          </AreaCard>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div>
                <Row gutter={[32, 32]}>
                  {list[3].section.map((v, i) => {
                    return (
                      <Col key={i} xs={24} lg={8}>
                        <Link className="-dc-thematic-areas-card" to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${v.id}`}>
                          <AreaCard>
                            <span>
                              <img height={100} src={v.icon} />
                            </span>
                            <h3>{v.label}</h3>
                          </AreaCard>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Carousel>
          </CarouselSection>
        </Container>
      </StyledLayout>
    </>
  );
};
