import { useCallback } from "react";
import DataCommonsClient from "../templates/DataCommons/utils/DataCommonsClient";
import { WEB_API_ENDPOINT } from "../templates/DataCommons/utils/constants";
interface pointRequest {
    variables: string[]; entities: string
}
const useFetchPoints = () => {
    // const [topics, setTopics] = useState([]);
    
    const fetchPoints = useCallback(async ({variables, entities}:pointRequest) => {
      
      const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });
  
      try {
        // Get all SDGs articles
        const response = await client.getPoints(variables, entities);
  
        return response
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    }, [])
  
    return { fetchPoints };
  }
  
  export default useFetchPoints;