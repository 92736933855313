/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Layout } from "antd";
import styled from "styled-components";
import { GoalSection } from "../home/GoalSection";
import { PlaceSection } from "../home/PlaceSection";
import { HomeSection, SectionDescription } from "../home/components";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ExploreSection } from "./ExploreSection";

const HomeContainer = styled(HomeSection)`
  background-color: #f2f2f2;
  gap: 45px;

  .page-header {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 627px;

    img {
      width: 100%;
    }
  }
`;

const Landing = () => {
  return (
    <>
      <AppLayoutContent
        style={{
          background: "white",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HomeContainer style={{paddingTop: '0'}}>
          <Layout className="container" style={{background: "transparent"}}>
            <AppBreadcrumb>SDGs</AppBreadcrumb>
          </Layout>
          <div className="page-header">
            <img src={"./images/datacommons/un-dc-sdg-logo.svg"} />
          </div>
          <SectionDescription>
          Navigate with precision and explore data insights on the Sustainable Development Goals (SDGs) through UN Data’s innovative feature – UN Data Commons for the SDGs. 
          </SectionDescription>
        </HomeContainer>
        <PlaceSection />
        <GoalSection />
        <ExploreSection />
      </AppLayoutContent>
    </>
  );
};

export default Landing;
