import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useFetchArticles, {
  GroupedArticles,
  Loading,
} from "../../../../../hooks/useArticles";
import { RootTopic, useStoreState } from "../../../state";
import { ROOT_TOPIC } from "../../../utils/constants";
import { ErorrMessageText, Spinner } from "../../countries/CountriesContent";
import { StoryBreadcrumbs } from "../../goals/DataStories";
import { ContentCard, Divider } from "../components";
import { Article } from "./GoalOverview";

const IndicatorOverview: React.FC<{
  color: string;
  dcid: string;
  limit?: number;
  showNotFound?: boolean;
}> = ({ color, dcid, limit, showNotFound }) => {
  const { fetchArticles } = useFetchArticles();
  const [topics, setTopics] = useState<GroupedArticles | Loading>({
    loading: true,
  });
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
  // const target = dcid.split('_')[1]
  // const indicators = useStoreState((s) => s.topics);
  // const name = indicators.byDcid[`${ROOT_TOPIC}_${target}`]?.name
  const rootTopics = useStoreState((s) => s.rootTopics);

  const getGoalName = (rootTopics: RootTopic[], target: string) => {
    return rootTopics.filter((a) => a.topicDcid == `${ROOT_TOPIC}_${target}`)[0]
      ?.name;
  };
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    if (dcid) {
      setBreadcrumbs(
        dcid
          .split("_")[1]
          .split(".")
          .map((_, i, arr) => arr.slice(0, i + 1).join("."))
      );

      fetchArticles([dcid]).then((resp) => {
        if (isMounted.current) setTopics(resp);
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [dcid]);

  const size = limit||(topics && topics[dcid] && Object.keys(topics[dcid]).length);
  return (
    <>
      {topics &&
        topics[dcid] &&
        Object.keys(topics[dcid])?.slice(0, size).map((item, i) => {
          let story = topics[dcid][item];
          return (
            <IndicatorStory key={i}>
              <IndicatorHeader color={color}>
                {breadcrumbs.length && <StoryBreadcrumbs breadcrumbs={breadcrumbs} goalName={getGoalName(rootTopics, breadcrumbs[0])}/>}
                <h4>{story.name}</h4>
                <Divider color={color} />
              </IndicatorHeader>

              <StoryContent content={story} />
            </IndicatorStory>
          );
        })}
      {topics && topics.loading && (
        <ContentCard>
          <Spinner />
        </ContentCard>
      )}
      {!topics && !topics?.loading && showNotFound && <ContentCard>
            <ErorrMessageText>No data found.</ErorrMessageText>
          </ContentCard> }
    </>
  );
};
export default IndicatorOverview;

export const StoryContent: React.FC<{
  color?: string;
  content: Article;
}> = ({ content }) => {
  return (
    <StoryBody>
      <div
        className="story-text"
        dangerouslySetInnerHTML={{
          __html: content
            ? `${content.text?.replace(/\\n/g, "<br/>")}${
                !!content.footnote ?
                `<p><br/>*Footnote: <i>${content.footnote}</i></p>` : ``
              }<p>${
                !!content.source ?
                `<br/>Source: <a href="${content.sourceUrl}" target="_blank" class="blue">${content.source}</a>` : ``
              }<br/>Published: ${
                content.createdAt &&
                new Date(content.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                })
              }`
            : "",
        }}
      ></div>
      {content.image && (
        <div className="image">
          <h6>{content.caption}</h6>
          <img src={content.image} alt={content.caption} />
          <p>
            {content.note && `${content.note.replace(/\\n/g, "<br/>")}`}
            {content.imageSource}
          </p>
        </div>
      )}
    </StoryBody>
  );
};

const IndicatorStory = styled.div`
  background: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
`;
const StoryBody = styled.div`
  padding: 0 16px 24px;
  font-size: 14px;
  .image {
    padding-top: 1.5rem;
    width: 100%;
    img {
      width: 100%;
    }
    h6 {
      padding-bottom: 0.5rem;
    }
    p {
      font-size: 12px;
    }
  }
  .blue {
    color: #027dbc;
  }
  .story-text {
    p {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) {
    display: flex;
    padding: 0 24px 24px;
    .image {
      padding-top: 0;
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      padding-left: 2rem;
    }
  }
@media (min-width: 1700px) {
    display: flex;
    padding: 0 24px 24px;
    .image {
      padding-top: 0;
      width: 475px;
      min-width: 475px;
      max-width: 475px;
      padding-left: 2rem;
    }
  }
`;
export const IndicatorHeader = styled.div<{ color: string }>`
  ${({ color }) => `
    color: ${color};
    background: #fff;
    margin: 0 0 -8px;
    padding: 0 16px;
    border-radius: 8px 8px 0 0;
    h3 {
        color: ${color};
        font-size: 32px;
        font-weight: 400;
    }
    h4 {
        color: ${color};
        font-size: 24px;
        font-weight: 400;
    }
    >ul{
        display: flex;
        align-items: center;
        list-style:none; 
        padding: 0;
        margin-bottom: 28px;
        font-size:14px;
        li:nth-child(2) a span, 
        li:nth-child(3) a span {
            border-radius: 6px;
            color: #fff;
            background: ${color};
            padding: 0 8px;
            margin-left:12px;
        }
        li:nth-child(2) a span{
            background: ${color}b8;
            margin-left:20px;
        }
        li:first-child a{
            height: 50px;
            background: ${color}10; 
            color: #000;
            display: flex;
            align-items: flex-end;
            padding: 0 16px 6px;
            font-weight: 500;
            line-height: 20px;
            span {
                font-size:24px;
                color: ${color};
                line-height: 28px;
                padding-right: 12px;
            }
            @media(min-width: 992px){
                height: 40px;
            }
        }
    } 
    @media(min-width: 992px){
        padding: 0 24px;
    }
`}
`;
