import { Layout } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../state";
import { EARTH_PLACE_DCID } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { ErorrMessageText, getGoalTargetIndicator, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import ExploreGoal from "./ExploreGoal";
import ExploreIndicator from "./ExploreIndicator";
import ExploreTarget from "./ExploreTarget";
import GoalOverviewList from "./GoalOverviewList";

export const unSort = (a:any, b:any) => {
  const [numA, suffixA] = a.split('.');
  const [numB, suffixB] = b.split('.');
  const numComparison = parseInt(numA) - parseInt(numB);
  if (numComparison !== 0) {
      return numComparison;
  }
  // Handle the case where suffix is numeric
  if (!isNaN(suffixA) && !isNaN(suffixB)) {
      return parseInt(suffixA) - parseInt(suffixB);
  }
  // Handle the case where suffix is alphabetic
  if (isNaN(suffixA) && isNaN(suffixB)) {
      return suffixA.localeCompare(suffixB);
  }
  // Handle mixed case: numeric suffixes should come before alphabetic suffixes
  return isNaN(suffixA) ? 1 : -1;
}

const GoalContent: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    selectedVariableDcids: string[];
    variableDcids: string[];
    isSearch: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined
  }> = ({fulfillResponse, placeDcids, variableDcids}) => {
    const location = useLocation()
    const params = Object.fromEntries(new URLSearchParams(location.search))
    const [goal, target, indicator] = params.v ? getGoalTargetIndicator(params.v) : []
    const rootTopics = useStoreState((s) => s.rootTopics);
    const [localIsFetchingFulfillment, setLocalIsFetchingFulfillment] = useState(false);
    const [localFulfillResponse, setLocalFulfillResponse] = useState<FulfillResponse>();
    const fetchTopicFulfillment = useStoreActions((a) => a.fetchTopicFulfillment);
    const fulfillmentsById = useStoreState((s) => s.fulfillments.byId);
    

    useEffect(() => {
      document.getElementById(`top`)?.scrollIntoView({ behavior: "smooth" });
      // If a fulfill response was passed in, use that
      // Otherwise fetch a fulfill response based on the specified variables & place
      if (!variableDcids || variableDcids.length === 0 || placeDcids.length === 0) {
        return;
      }
      (async () => {
        setLocalIsFetchingFulfillment(true);
        const topicDcids = variableDcids.map((dcid) => {
          if (dcid.indexOf("/g/") !== -1) {
            return dcid.replace("/g/", "/topic/").toLocaleLowerCase();
          }
          return dcid;
        });

        const fulfillment = await fetchTopicFulfillment({
          entityDcids: placeDcids,
          variableDcids: topicDcids,
          fulfillmentsById,
        });
        setLocalIsFetchingFulfillment(false);
        setLocalFulfillResponse(fulfillment);
      })();
    }, [fulfillResponse, placeDcids, variableDcids]);


    if(indicator && indicator !== 'none' && goal && target){
      return localFulfillResponse ? <ExploreIndicator
          goal={goal} 
          target={target} 
          indicator={indicator} 
          fulfillResponse={localFulfillResponse}
          placeDcids={[EARTH_PLACE_DCID]} 
      /> 
      : <ContentCard>
        {localIsFetchingFulfillment ? <Spinner/>: <ErorrMessageText>No data found</ErorrMessageText>}
      </ContentCard> 
    }
          
    if(target && goal && target !== 'none'){
      return localFulfillResponse ? <ExploreTarget
          goal={goal} 
          fulfillResponse={localFulfillResponse}
          target={target} 
          // variableDcids={[variableDcid]} 
          placeDcids={[EARTH_PLACE_DCID]} 
          // setPlaceDcid={() => {}} 
      /> 
      : <ContentCard>
        {localIsFetchingFulfillment ? <Spinner/>: <ErorrMessageText>No data found</ErorrMessageText>}
      </ContentCard> 
    }

    if(goal && goal !== 'none'){
      return localFulfillResponse ? <ExploreGoal 
          goal={goal} 
          fulfillResponse={localFulfillResponse}
          // hidePlaceSearch={true} 
          // variableDcids={[variableDcid]} 
          placeDcids={[EARTH_PLACE_DCID]} 
          // setPlaceDcid={() => {}} 
      /> 
      : <ContentCard>
        {localIsFetchingFulfillment ? <Spinner/>: <ErorrMessageText>No data found</ErorrMessageText>}
      </ContentCard> 
    }

    return <Layout style={{ height: "100%", flexGrow: 1 }} id="top">
                <Layout.Content style={{ padding: "24px 0", background: theme.searchBackgroundColor }}>
                    <GoalOverviewList rootTopics={rootTopics} />
                </Layout.Content>
            </Layout>
        
  }
export default GoalContent