import { Table } from "antd";
import React from "react";
import styled from "styled-components";

const Body = styled.div`
  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: solid 1px #dddae5;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .desc {
    color: #616161;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
  }
  h4 {
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
  }
  .ant-pagination a:not([href]) {
    color: #283460 !important;
  }
  .ant-pagination .ant-pagination-item-active a:not([href]) {
    color: #0b57d0 !important;
  }

  .ant-pagination .ant-pagination-item-active {
    border-color: #0b57d0 !important;
  }

  .ant-pagination .ant-pagination-prev {
    border: 0;
    padding: 0;
  }

  .ant-pagination .ant-pagination-jump-prev {
    border: 0;
    margin: 0 8px;
  }
  .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const DataCatalogue: React.FC<{ props: object }> = ({ props }) => {
  return (
    <Body id="block-4">
      <h3 className="title">Data Catalogue</h3>
      <Table columns={props.columns} dataSource={props.dataSource} />
    </Body>
  );
};
