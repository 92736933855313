/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from "styled-components";

/**
 * Styling for a section on the homepage.
 */
export const HomeSection = styled.div`
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  flex-shrink: 0;
  
`

/**
 * Styling for the header for each section.
 */
export const SectionHeader = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`

/**
 * Styling for the description for each section.
 */
export const SectionDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  max-width: 886px;
  @media (max-width: 768px){
    padding: 10px 20px;
    font-size:14px;
  }
`

/**
 * Styling for search bar containers on the home page.
 */
export const HomeSearchContainer = styled.div`
  max-width: 778px;
  width: 100%;
  height: 45px;
  @media (max-width: 768px){
    height:40px;
  }
`

export const ColorBar = styled.div`
background: repeating-linear-gradient(90deg, 
  #ff001b, #ff001b 5.8%, 
  #e49b00 5.8%, #e49b00 11.6%, 
  #019e3b 11.6%, #019e3b 17.4%, 
  #e6001f 17.4%, #e6001f 23.2%, 
  #ff0000 23.2%, #ff0000 29%, 
  #02b1e2 29%, #02b1e2 34.8%, 
  #ffb202 34.8%, #ffb202 40.6%, 
  #ac0034 40.6%, #ac0034 46.4%, 
  #ff5602 46.4%, #ff5602 52.2%, 
  #ff0087 52.2%, #ff0087 58%, 
  #ff9301 58%, #ff9301 63.8%, 
  #e58501 63.8%, #e58501 69.6%, 
  #0a7c28 69.6%, #0a7c28 75.4%, 
  #027fc6 75.4%, #027fc6 81.2%, 
  #00b616 81.2%, #00b616 87%, 
  #005692 87%, #005692 92.8%, 
  #02366f 92.8%, #02366f 100%);
  height: 10px;
  @media (max-width: 768px){
    height: 5px;
  }
`;