import React, { useEffect, useState } from 'react';
import useFetchArticles, { GroupedArticles } from "../../../../hooks/useArticles";
import { RootTopic } from '../../state';
import { Spinner } from '../countries/CountriesContent';
import { MainLayoutContent } from '../shared/components';
import GoalOverview, { Article } from '../shared/goals/GoalOverview';

const GoalOverviewList: React.FC<{ rootTopics: RootTopic[] }> = ({ rootTopics }) => {
  const { fetchArticles } = useFetchArticles();
  const [topics, setTopics] = useState<GroupedArticles | null>({});
  useEffect(() => {
    if(rootTopics?.length)
    fetchArticles(rootTopics.map(a => a.topicDcid)).then(resp=>{
        setTopics(resp)
    });
  }, [rootTopics]);

  return (
    <>{Object.keys(topics).length ? 
      Object.keys(topics).sort((a, b) => {
        const numA = parseInt(a.match(/\d+/)[0]);
        const numB = parseInt(b.match(/\d+/)[0]);
        return numA - numB;
        }).map((i:string, topicIndex:number) => (
        <MainLayoutContent key={topicIndex}>
          <GoalOverview goalData={topics[i] as Article} goalNumber={topicIndex + 1} showExploreLink={true} />
        </MainLayoutContent>
      )):
      <Spinner/>
    }
    </>
  );
};

export default GoalOverviewList;
