/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { HomeSection, SectionHeader } from "./components";
// @ts-ignore
import { CloseOutlined } from "@ant-design/icons";
import { Carousel, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const Container = styled(HomeSection)`
  text-align: left;
  .di-box {
    width: 100%;
    display: block;
    text-align: center;
    img {
      height: 100%;
      position: static;
    }
    h3 {
      font-size: 22px;
      text-align: left;
      padding: 1rem;
      min-height: 85px;
    }
    span p {
      padding-right: 1rem;
      display: block;
      color: #7c7c7c;
      font-size: 16px;
      text-align: left;
      min-height: 110px;
    }
  }
`;

const Header = styled(SectionHeader)`
  width: 100%;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  padding: 0 16px;
  > .ant-row {
    border-bottom: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  }
  a {
    font-size: 14px;
    color: #283460;
  }
`;

const ModalBody = styled.div`
  h3 {
    display: block;
    color: #333;
    font-size: 24px;
    padding-bottom: 20px;
    font-weight: normal;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  img {
    width: 60%;
  }
  h2 {
    padding: 2rem 0;
  }
  span p {
    font-size: 14px;
  }
  .modal-img {
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    @media (max-width: 767px) {
      height: 200px;
    }
    @media (max-width: 480px) {
      height: 160px;
    }
  }
  .image-credit {
    color: #7c7c7c;
    font-size: 12px;
    padding-top: 8px;
    i {
      font-style: italic;
    }
  }
  .modal-url {
    padding: 1.5rem 0;
    a {
      color: #283460;
    }
  }
  .modal-links {
    background-color: #f1f1f1;
    margin: 24px -24px -24px -24px;
    padding: 24px;
    span {
      color: #404040;
      font-weight: bold;
    }
    a {
      color: #283460;
    }
  }
  .video-container {
    width: 100%;
    display: block;
    padding: 2rem 0 2rem 0;
  }

  .react-player {
    margin: 0 auto;
  }
  .rsvp {
    font-weight: bold;
    padding-bottom: 1rem;
    display: block;
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-close-x {
    width: 100px;
    padding-top: 1rem;
  }
  .anticon-close {
    background: #fff;
    padding: 4px;
    border-radius: 2px;
    opacity: 0.8;
  }
  .ant-modal-body {
    max-height: none !important;
  }
`;

const Body = styled.div`
  border: solid 1px #efefef;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  span p {
    color: #404040;
    font-size: 14px;
    padding: 0.5rem 0 1rem 1rem;
    line-height: 22px;
  }
`;

const Frame = styled.div`
  .img-wrapper {
    height: 150px;
    background-size: cover;
    background-position: center;
  }
`;

const CarouselSection = styled.div`
  height: 100%;
  width: 100%;
  display: "block";
  padding: 2rem 0;
  .ant-carousel .slick-list {
    transition:all .6s;
  }
  .ant-carousel .slick-slider .slick-dots-bottom {
    bottom: -60px;
  }
  .ant-carousel .slick-slider .slick-dots li.slick-active button {
    border: 3px solid #283460;
    background-color: #283460;
  }
  .ant-carousel .slick-slider .slick-dots li button {
    width: 12px;
    height: 12px;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-color: #d9d9d9;
  }
  .ant-carousel .slick-slide {
    padding: 0 16px;
  }
  .ant-carousel .slick-slide img{
    display: unset;
  }
}
`;

const CustomDots = ({ news }) => {
  const sections = Math.ceil(news.length / 3);

  return (
    <ul style={{ listStyle: "none" }} className="slick-dots">
      {Array.from({ length: sections }).map((_, index) => (
        <li key={index} className={index === 0 ? "slick-active" : ""}></li>
      ))}
    </ul>
  );
};

export const News = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [news, setNews] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/news.json`);
        const json = await response.json();
        setNews(json.filter((v) => v.category == "news" && v.featured == 1));
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchData();
  }, []);

  const showModal = (item) => {
    setItem(item);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showFewWords = (sentence, limit) => {
    const words = sentence.split(" ").slice(0, limit);
    const result = words.join(" ");
    return sentence.split(" ").length > limit ? `${result}...` : result;
  };

  const insertVideo = (text) => {
    const segments = text.split("[video]");

    return (
      <div>
        {segments.map((segment, index) => (
          <React.Fragment key={index}>
            <div dangerouslySetInnerHTML={{ __html: segment }}></div>
            {index < segments.length - 1 && (
              <div className="video-container">
                <ReactPlayer
                  url="./images/datacommons/landing2.mp4"
                  className="react-player"
                  width="70%"
                  height="100%"
                  muted={true}
                  playing={true}
                  controls={true}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const settings = {
    adaptiveHeight: true,
  };

  return (
    <>
      <Container style={{ padding: "2rem 0" }} className="container">
        <Header>
          <Row>
            <Col style={{ textAlign: "left" }} lg={22}>
              News
            </Col>
            <Col style={{ textAlign: "right" }} lg={2}></Col>
          </Row>
        </Header>

        {news && news.length > 0 && (
          <CarouselSection>
            <Carousel autoplay autoplaySpeed={15000} {...settings}>
              {Array.from({ length: Math.ceil(news.length / 3) }).map((_, sectionIndex) => (
                <div key={sectionIndex}>
                  <Row gutter={[32, 32]}>
                    {news.slice(sectionIndex * 3, sectionIndex * 3 + 3).map((v, i) => (
                      <Col key={i} xs={24} lg={8} style={{ cursor: "pointer", marginBottom: "10px" }} onClick={(e) => showModal(v)}>
                        <Body style={{ borderTop: `8px solid #1A6BA1` }}>
                          <Frame style={{ padding: "" }}>
                            <div style={{ textAlign: "left", padding: "" }}>
                              <div className="di-box">
                                <div style={{ backgroundImage: `url(./images/datacommons/${v.image})` }} className="img-wrapper" />
                                <h3>{showFewWords(v.title, 12)}</h3>
                                <span dangerouslySetInnerHTML={{ __html: showFewWords(v.text, 20) }} />
                              </div>
                            </div>
                          </Frame>
                        </Body>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </Carousel>
            <CustomDots news={news} />
          </CarouselSection>
        )}

        <CustomModal
          closeIcon={<CloseOutlined />}
          width={800}
          bodyStyle={{ borderRadius: "8px" }}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <ModalBody>
            {item && (
              <>
                <div style={{ backgroundImage: `url(./images/datacommons/${item.image})` }} className="modal-img" />
                <div className="image-credit">{item.credit ? <span dangerouslySetInnerHTML={{ __html: `Image credit: ${item?.credit}` }}></span> : ""}</div>
                <h2>
                  {item.title}
                  <h4 style={{ paddingTop: "4px" }}>{item.subtitle}</h4>
                </h2>
                {item?.rsvp && <span className="rsvp" dangerouslySetInnerHTML={{ __html: `${item?.rsvp}` }}></span>}

                <span>{insertVideo(item.text)}</span>
                {item.url && (
                  <div className="modal-url">
                    <span>Learn more: </span>
                    <a href={item.url.url} target="_blank">
                      {item.url.name} <img style={{ width: "14px", paddingBottom: "2px" }} src="./images/datacommons/open_in_new.svg" />
                    </a>
                  </div>
                )}

                {item.links.length > 0 && (
                  <div className="modal-links">
                    <span>Additional information links</span>
                    <ul>
                      {item.links.map((v) => {
                        return (
                          <li key={v.name}>
                            <a href={v.url} target="_blank">
                              {v.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            )}
          </ModalBody>
        </CustomModal>
      </Container>
    </>
  );
};
