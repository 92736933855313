// LineChart.js

import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  padding: 10px;
  pointer-events: none;
  visibility: hidden;
  z-index: 10;
  max-width: 200px;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Title = styled.h3`
  padding: 30px 0 4px 10px;
  text-align: left;
  margin-bottom: 10px;
`;

const SourceLink = styled.a`
  display: block;
  padding-left: 10px;
  padding-bottom: 20px;
  text-align: left;
  font-size: 12px;
  color: #1f78b4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LineChart = ({ data, height, title, source, sourceLink }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const tooltipRef = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // Update width based on container size
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (width === 0) return; // Wait until width is set

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous elements

    const margin = { top: 20, right: 30, bottom: 100, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = d3
      .scaleTime()
      .domain([d3.min(data, (d) => d3.min(d.values, (v) => v.date)), d3.max(data, (d) => d3.max(d.values, (v) => v.date))])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d3.max(d.values, (v) => v.value))])
      .nice()
      .range([innerHeight, 0]);

    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value));

    const colors = d3.scaleOrdinal().range(d3.schemeCategory10);

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Append lines
    g.selectAll(".line-group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "line-group")
      .append("path")
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", (d, i) => colors(i))
      .attr("stroke-width", 2)
      .attr("d", (d) => line(d.values));

    // Calculate tick values from data, excluding the first and last year, and only every 5 years
    const allDates = data.flatMap((d) => d.values.map((v) => v.date));
    const uniqueYears = Array.from(new Set(allDates.map((d) => d.getFullYear())));
    const tickValues = uniqueYears.filter((year) => year % 5 === 0).map((year) => new Date(year, 0, 1));

    // Append x-axis with custom tick values
    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat("%Y")).tickValues(tickValues);
    g.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(xAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").clone().attr("y2", -innerHeight).attr("stroke-opacity", 0.1))
      .call((g) => g.append("text").attr("x", innerWidth).attr("y", -4).attr("fill", "currentColor").attr("text-anchor", "end").text(data.x));

    // Append y-axis with percentage format
    g.append("g")
      .call(d3.axisLeft(yScale).tickFormat((d) => `${d}%`))
      .call((g) => g.select(".domain").remove())
      .call((g) => g.selectAll(".tick line").clone().attr("x2", innerWidth).attr("stroke-opacity", 0.1))
      .call((g) => g.append("text").attr("x", -4).attr("y", 10).attr("fill", "currentColor").attr("text-anchor", "end").text(data.y));

    // Append legend at the bottom
    const legend = svg.append("g").attr("transform", `translate(${margin.left},${height - margin.bottom + 40})`);

    let legendX = 0;

    data.forEach((group, i) => {
      const legendRow = legend.append("g").attr("transform", `translate(${legendX}, 0)`);

      legendRow.append("circle").attr("cx", 5).attr("cy", 5).attr("r", 5).attr("fill", colors(i));

      legendRow.append("text").attr("x", 15).attr("y", 10).attr("fill", "black").style("font-size", "12px").text(group.name);

      legendX += legendRow.node().getBBox().width + 20; // Adjust the horizontal spacing
    });

    // Append overlay for hover effect
    const overlay = svg
      .append("rect")
      .attr("width", innerWidth)
      .attr("height", innerHeight)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .on("mousemove", function (event) {
        const [x] = d3.pointer(event);
        const x0 = xScale.invert(x);
        const bisectDate = d3.bisector((d) => d.date).left;
        const tooltipData = data.map((group) => {
          const index = bisectDate(group.values, x0, 1);
          const a = group.values[index - 1];
          const b = group.values[index];
          const d = x0 - a.date > b.date - x0 ? b : a;
          return { name: group.name, value: d.value.toFixed(2), date: d.date };
        });

        // Update tooltip
        const tooltipHtml = tooltipData.map((d) => `<strong>${d.name}:</strong> ${d.value}%`).join("<br>");
        const tooltip = d3.select(tooltipRef.current);

        const tooltipLeft =
          d3.pointer(event, svg.node())[0] + margin.left + tooltip.node().offsetWidth > width
            ? d3.pointer(event, svg.node())[0] + margin.left - tooltip.node().offsetWidth
            : d3.pointer(event, svg.node())[0] + margin.left;

        tooltip
          .style("visibility", "visible")
          .style("left", `${tooltipLeft}px`)
          .style("top", `${d3.pointer(event, svg.node())[1] + margin.top}px`)
          .html(`<strong>${tooltipData[0].date.getFullYear()}</strong><br>${tooltipHtml}`);

        // Highlight the selected year and show dots
        svg
          .selectAll(".dot")
          .data(tooltipData)
          .join("circle")
          .attr("class", "dot")
          .attr("cx", (d) => xScale(d.date))
          .attr("cy", (d) => yScale(d.value))
          .attr("r", 3)
          .attr("fill", (d, i) => colors(i))
          .attr("transform", `translate(${margin.left},${margin.top})`);
      })
      .on("mouseout", function () {
        d3.select(tooltipRef.current).style("visibility", "hidden");
        svg.selectAll(".dot").remove();
      });
  }, [data, height, width]);

  return (
    <ChartContainer ref={containerRef}>
      <Title>{title}</Title>
      <SourceLink href={sourceLink} target="_blank" rel="noopener noreferrer">
        Source: <span style={{ color: "#0b57d0" }}>{source}</span>
      </SourceLink>
      <svg ref={svgRef} width={width} height={height}></svg>
      <Tooltip ref={tooltipRef}></Tooltip>
    </ChartContainer>
  );
};

export default LineChart;
