import { GlobalOutlined, HomeOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Col } from "antd";
import React, { Component } from "react";

import styled from "styled-components";
import { Text } from "../../../containers/Language";
import { routePathConstants } from "../../../helper/Common/RoutePathConstants";
const style = { width: "26px", margin: "0 10px 0 0", float: "left" };
const StyledRow = styled(Breadcrumb)`
.ant-breadcrumb{
    height:22px;
    overflow:hidden;
}
.anticon.anticon-home svg,
.anticon.anticon-global svg {
    width: 18px!important;
}
.lng {
    line-height:24px;
    text-align: right;
    font-size:12px;
    span{
        color:#343434;
    }
}
@media (max-width: 768px) {
    > ol li:after {
        content: "...";
        display: inline-block;
        width: 20px;
        height: 14px;
        font-size: 20px;
        line-height: 14px;
    }
}
`
export default class TopNavigation extends Component {
    render() {
        return (
            <React.Fragment>
                <StyledRow gutter={{ xs: 32, sm: 32, md: 32, lg: 32 }}>
                    <Col
                        className='gutter-row'
                        xs={{ span: 16 }}
                        md={{ span: 16 }}
                        lg={{ span: 16 }}
                    >
                        {/* <img
                            src='images/logo.png'
                            alt=''
                           
                            className='un-logo xs-hidden'
                        /> */}
                        <Breadcrumb style={{marginTop: '4px'}} separator={<RightOutlined />}>
                            <Breadcrumb.Item>
                                <a
                                    href='https://www.un.org/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <HomeOutlined style={{fontSize: '16px', width:'18px', margin: '-4px 16px auto 8px'}} /><Text tid='TopNavBreadcrumb1' />
                                </a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a
                                    href='https://www.un.org/en/desa'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <Text tid='TopNavBreadcrumb2' />
                                </a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href={routePathConstants.HOME_PATH} rel='noopener noreferrer'>
                                    <Text tid="TopNavBreadcrumb3" />
                                </a>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col
                        className='gutter-row lng'
                        xs={{ span: 8 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}
                    >
                     <GlobalOutlined /> <span>English</span>
                    </Col>
                </StyledRow>
            </React.Fragment>
        );
    }
}
