import { Col, Collapse, Row } from "antd";
import React from "react";
import styled from "styled-components";

const { Panel } = Collapse;

const Body = styled.div`
  padding-bottom: 2rem;
  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: solid 1px #dddae5;
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
  p {
    padding: 1rem 0 0 0;
    line-height: 22px;
  }
  .desc {
    color: #616161;
    font-size: 16px;
    padding: 0;
  }
  h4 {
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
  }
  .non-collapsible-panel .ant-collapse-arrow {
    display: none !important;
  }
  .ant-collapse-header-text {
    width: 100% !important;
  }
  .ant-collapse-header {
    padding-right: 16px !important;
    padding-left: 0 !important;
  }
  .ant-collapse-item {
    border-bottom: solid 1px #dddae5;
  }
  .first-panel {
    border-top: solid 1px #dddae5;
  }
  .ant-collapse-content-box {
    padding-bottom: 32px !important;
  }
`;

export const Overview: React.FC<{ props: object }> = ({ props }) => {
  const CollapseHeader = (label, link) => (
    <div style={{ display: "inline-flex", width: "100%" }}>
      <a style={{ color: "#000", flexGrow: 1, fontSize: "20px" }} href={link} onClick={handleHeaderClick} target="_blank">
        {label}
        <span style={{ paddingLeft: "6px" }}>
          <img style={{ width: "16px", marginTop: "-2px" }} src="./images/datacommons/open_in_new.svg" />
        </span>
      </a>
    </div>
  );

  const CustomHeader = (label) => <span style={{ flexGrow: 1, fontSize: "20px" }}>{label}</span>;

  const handleHeaderClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Body id="block-1">
      <Row style={{ paddingTop: "1.5rem", paddingBottom: "2rem" }} gutter={[48, 48]}>
        <Col xs={24} lg={8}>
          <div style={{ textAlign: "center", paddingBottom: "2rem" }}>
            <img width={props.width ? props.width : 250} src={`./images/datacommons/${props.logo}`} />
          </div>
          <Collapse ghost expandIconPosition="end" defaultActiveKey={["2"]}>
            <Panel header={CollapseHeader("Website", props?.website)} key="1" onClick={handleHeaderClick} className="non-collapsible-panel first-panel" />
            <Panel header={CustomHeader("Database(s)")} key="2">
              {props.databases.map((v) => {
                return (
                  <p style={{ padding: 0 }}>
                    <a href={v.url} target="_blank">
                      {v.url}
                    </a>
                  </p>
                );
              })}
            </Panel>
            <Panel header={CollapseHeader("GitHub", props?.github)} key="3" onClick={handleHeaderClick} className="non-collapsible-panel" />
            <Panel header={CollapseHeader("Copyright", props?.copyright)} key="3" onClick={handleHeaderClick} className="non-collapsible-panel" />
          </Collapse>
        </Col>
        <Col sm={24} lg={12}>
          <h3>{props?.label}</h3>
          <span className="desc" dangerouslySetInnerHTML={{ __html: props?.body }} />
        </Col>
      </Row>
    </Body>
  );
};
