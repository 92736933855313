/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadingOutlined } from "@ant-design/icons";
import { DataCommonsClient } from "@datacommonsorg/client";
import { Card, Col, Collapse, Layout, Row, Spin } from "antd";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { WEB_API_ENDPOINT } from "../../utils/constants";
import AppLayoutContent from "../shared/AppLayoutContent";
import LineChart from "./LineChart";

const ChartHeader = styled.div`
  padding-bottom: 2rem;
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  p {
    padding: 0;
  }
`;

const { Panel } = Collapse;

const Goals: React.FC<{
  placeDcid: string;
  variableDcid: string;
  //setVariableDcid: (variableDcid: string) => void;
}> = (props) => {
  const { placeDcid } = props;
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [fullData, setFullData] = useState([]);
  const [data, setData] = useState([]);
  const [mapData, setMapData] = useState([]);

  const svgRef = useRef();

  const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });

  async function getDataRowSeries<T>(codes: Array): Promise<T> {
    const response = client.getDataRowSeries({
      variables: codes, //who/WHS4_100, HIV_0000000021
      parentEntity: "Earth",
      childType: "Country",
    });
    return await (response as Promise<T>);
  }

  async function getDataRows<T>(codes: Array): Promise<T> {
    const response = client.getDataRowSeries({
      variables: codes, //who/WHS4_100, HIV_0000000021
      parentEntity: "Earth",
      childType: "Country",
    });
    return await (response as Promise<T>);
  }

  const calculateAverageByYearForGroup = (data) => {
    const groupMap = {};

    data.forEach((entry) => {
      const group = entry.variable__dcid;
      const year = entry.variable__date;
      const value = entry.variable__value;

      if (!groupMap[group]) {
        groupMap[group] = {};
      }

      if (!groupMap[group][year]) {
        groupMap[group][year] = { sum: 0, count: 0 };
      }

      groupMap[group][year].sum += value;
      groupMap[group][year].count += 1;
    });

    const transformedData = [];

    //tmp need to figure out how to get the proper labels
    const groupNames = {
      "sdg/SI_POV_EMP1.AGE--Y15T24": "15 to 24 years old",
      "sdg/SI_POV_EMP1.AGE--Y15T24__SEX--F": "15 to 24 years old, Female",
      "sdg/SI_POV_EMP1.AGE--Y15T24__SEX--M": "15 to 24 years old, Male",
    };

    Object.keys(groupMap).forEach((group) => {
      const averages = Object.keys(groupMap[group]).map((year) => ({
        date: new Date(year, 0, 1),
        value: groupMap[group][year].sum / groupMap[group][year].count,
      }));

      transformedData.push({
        name: groupNames[group],
        values: averages,
      });
    });

    return transformedData;
  };

  useEffect(() => {
    getDataRows(["sdg/SI_POV_EMP1.AGE--Y15T24", "sdg/SI_POV_EMP1.AGE--Y15T24__SEX--F", "sdg/SI_POV_EMP1.AGE--Y15T24__SEX--M"]).then((res) => {
      //console.log(res);

      const transformedData = calculateAverageByYearForGroup(res);

      console.log(transformedData);

      setData(transformedData);
      setFullData(res);
    });
  }, []);

  useEffect(() => {}, []);

  const getCountryValue = (countryCode) => {
    const country = mapData.find((c) => c.entity__isoCode === countryCode);
    return country ? parseFloat(country.variable__value) : 0;
  };

  const Spinner = () => {
    return (
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              paddingLeft: "1.5rem",
              fontSize: "1.5rem",
            }}
            spin
          />
        }
      />
    );
  };

  return (
    <>
      <AppLayoutContent className="container">
        <Layout style={{ padding: "2rem" }}>
          <ChartHeader>
            <h2>Agency: SDG</h2>
            <p>X Total Views</p>
            <p>X Linked instances</p>
            <p>X Usage in products and or tools</p>
            <p>
              Indicator: <strong>Employed population below international poverty line [15 to 24 years old]</strong>
            </p>
            <p>
              Codes: <strong>sdg/SI_POV_EMP1.AGE--Y15T24, sdg/SI_POV_EMP1.AGE--Y15T24__SEX--F, sdg/SI_POV_EMP1.AGE--Y15T24__SEX--M</strong>
            </p>
          </ChartHeader>
          <Card style={{ marginBottom: "1rem" }}>
            <Row gutter={32}>
              <Col style={{ border: "solid 1px #efefef" }} lg={18}>
                <LineChart
                  title="Employed population below international poverty line [15 to 24 years old]"
                  source="Global SDG Database"
                  sourceLink="https://unstats.un.org/sdgs/dataportal"
                  data={data}
                  width={500}
                  height={500}
                />
              </Col>
              <Col lg={6}>
                <p>Chart ID: 1402745710-1</p>
                <p>
                  UN Data{" "}
                  <a style={{ color: "blue" }} target="_blank" href="https://unstats.un.org/UNSDWebsite/undatacommons/goals?v=dc%2Ftopic%2Fsdg_1">
                    location
                  </a>
                </p>
                <p>
                  Source:{" "}
                  <a style={{ color: "blue" }} target="_blank" href="https://unstats.un.org/sdgs/dataportal">
                    link
                  </a>
                </p>
              </Col>
            </Row>
          </Card>

          <Card style={{ marginBottom: "1rem" }}>
            <p>
              SDG Tags <br />
              <span style={{ color: "#0354a6" }}>G1, G2, G6, G11, G14</span>
            </p>

            <p>
              Thematic Tags
              <br />
              <span style={{ color: "#0354a6" }}>Theme 1, Theme 2</span>
            </p>
            <p>
              Data Insights Tags
              <br />
              <span style={{ color: "#0354a6" }}>Data Insight 1, Data Insight 3</span>
            </p>
            <p>
              Related to <br />
              <span style={{ color: "#0354a6" }}>SDG Report ./ ILO, UNECEF, Other Agency datapoints</span>
            </p>
            <p>
              Notes
              <br />
              <span style={{ color: "#0354a6" }}>
                Chart is limited on views and only show Geo maps <br />
                Maps are not scaled and only show default view
              </span>
            </p>

            <p>Data Set for this chart</p>
            <div>
              <Collapse>
                <Panel header="View Data" key="1">
                  <pre>{JSON.stringify(fullData, null, 2)}</pre>
                </Panel>
              </Collapse>
            </div>
          </Card>
        </Layout>
      </AppLayoutContent>
    </>
  );
};

export default Goals;
