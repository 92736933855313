/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { HomeSection, SectionHeader } from "./components";
// @ts-ignore
import { Col, Row } from "antd";
import { routeDataCommonsConstants } from "../../../../../src/helper/Common/RoutePathConstants";

const Container = styled(HomeSection)`
  text-align: left;
  .pbox {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: solid 1px #ececec;
    box-shadow: 2px 8px 4px #ececec;

    img {
      padding: 1rem;
      width: 100%;
      height: 100%;
      object-fit: fit;
    }
  }
  .partners-wrap {
    padding: 2rem 0;
    width: 100%;
    > div:first-child{
      padding-left:0!important;
    }
    > div:last-child{
      padding-right:0!important;
    }
    
    @media (min-width:414px) and (max-width:767px){
        .pbox {
          width: 175px;
          height: 175px;
        }
      > div:nth-child(2n+1){
        padding-left:0!important;
        .pbox {
          float:left;
        }
        .pbox.last {
          float:none;
        }
      }
      > div:nth-child(2n){
        padding-right:0!important;
        .pbox {
          float:right;
        }
      }
    }
  }
`;

const Header = styled(SectionHeader)`
  width: 100%;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  a {
    font-size: 14px;
    font-weight: 500;
    color: #283460;
    display:flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    white-space: nowrap;
  }
`;

export const Partners = () => {
  const history = useHistory();
  const list = [
    { value: "WHO", img: "who-b.svg" },
    { value: "ILO", img: "ilo2.svg" },
    { value: "ECLAC", img: "eclac-b.svg", padding: "2.5rem" },
    { value: "UNICEF", img: "unicef-b2.svg" },
    { value: "DESA", img: "desa-b.svg" },
  ];

  return (
    <>
      <Container style={{ padding: "2rem 16px" }} className="container">
        <Header>
          <Row>
            <Col style={{ textAlign: "left" }} xs={20}>
              Data Partners
            </Col>
            <Col style={{ textAlign: "right" }} xs={4}>
              <Link to={`${routeDataCommonsConstants.PARTNER}`}>See all 
                <span className="material-icons">
                  chevron_right
                </span>
              </Link>
            </Col>
          </Row>
        </Header>

        <Row className="partners-wrap" justify="center" gutter={[32, 32]}>
          {list.map((v,i) => {
            return (
              <Col key={i} align="center" flex="auto">
                <div className={`pbox ${i === list.length-1 ? `last` : ``}`}>
                  <Link to={`${routeDataCommonsConstants.PARTNER}/${v.value}`}>
                    <img style={{ padding: v.padding }} src={`./images/datacommons/${v.img}`} />
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
