import { CloseOutlined } from "@ant-design/icons";
import { Carousel, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const Body = styled.div`
  .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: solid 1px #dddae5;
    margin-bottom: 2rem;
    @media (min-width: 992px) {
      font-size: 24px;
    }
  }

  .desc {
    color: #616161;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
    p {
      font-size: 12px;
    }
  }
  h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .img {
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }
`;

const ModalBody = styled.div`
  h3 {
    display: block;
    color: #333;
    font-size: 24px;
    padding-bottom: 20px;
    font-weight: normal;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  img {
    width: 60%;
  }
  h2 {
    padding: 2rem 0;
  }
  span p {
    font-size: 14px;
  }
  .modal-img {
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    @media (max-width: 767px) {
      height: 200px;
    }
    @media (max-width: 480px) {
      height: 160px;
    }
  }
  .image-credit {
    color: #7c7c7c;
    font-size: 12px;
    padding-top: 8px;
    i {
      font-style: italic;
    }
  }
  .modal-url {
    padding: 1.5rem 0;
    a {
      color: #283460;
    }
  }
  .modal-links {
    background-color: #f1f1f1;
    margin: 24px -24px -24px -24px;
    padding: 24px;
    span {
      color: #404040;
      font-weight: bold;
    }
    a {
      color: #283460;
    }
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-close-x {
    width: 100px;
    padding-top: 1rem;
  }
  .anticon-close {
    background: #fff;
    padding: 4px;
    border-radius: 2px;
    opacity: 0.8;
  }
  .ant-modal-body {
    max-height: none !important;
  }
`;

export const DataInsights: React.FC<{ props: object }> = ({ props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [article, setArticle] = useState(null);

  const showModal = (item) => {
    setArticle(item);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showFewWords = (sentence, limit) => {
    const words = sentence.split(" ").slice(0, limit);
    const result = words.join(" ");
    return `${result}...`;
  };

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const newsChunks = chunkArray(props.news, 3);

  return (
    <Body id="block-2">
      <h3 className="title">Data Insights</h3>
      <Carousel style={{ marginBottom: "1rem" }} dotPosition="bottom">
        {newsChunks.map((chunk, index) => (
          <div key={index}>
            <Row style={{ paddingBottom: "2rem" }} gutter={[32, 64]}>
              {chunk.map((item) => (
                <Col key={item.title} style={{ cursor: "pointer", paddingBottom: "0" }} onClick={() => showModal(item)} lg={8}>
                  <Row gutter={[32, 32]}>
                    <Col xs={12} lg={8}>
                      <div style={{ backgroundImage: `url(${item.thumbnail ? item.thumbnail : item.image})` }} className="img" />
                    </Col>
                    <Col xs={12} lg={16}>
                      <h4>{item.title}</h4>
                      <span className="desc" dangerouslySetInnerHTML={{ __html: showFewWords(item.text, item.limit) }}></span>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Carousel>

      <CustomModal
        closeIcon={<CloseOutlined />}
        width={800}
        bodyStyle={{ borderRadius: "8px" }}
        visible={isModalOpen}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Read more"
        footer={null}
      >
        {article && (
          <ModalBody>
            <div style={{ backgroundImage: `url(${article.image})` }} className="modal-img" />
            <div className="image-credit">{article.credit ? <span dangerouslySetInnerHTML={{ __html: `Image credit: ${article?.credit}` }}></span> : ""}</div>
            <h2>{article.title}</h2>
            <span dangerouslySetInnerHTML={{ __html: article.text }}></span>

            {article.url && (
              <div className="modal-url">
                <span>Learn more: </span>
                <a href={article.url.url} target="_blank">
                  {article.url.name} <img style={{ width: "14px", paddingBottom: "2px" }} src="./images/datacommons/open_in_new.svg" />
                </a>
              </div>
            )}

            {article.links && article.links.length > 0 && (
              <div className="modal-links">
                <span>Additional information links</span>
                <ul>
                  {article.links.map((v) => {
                    return (
                      <li key={v.url}>
                        <a href={v.url} target="_blank">
                          {v.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </ModalBody>
        )}
      </CustomModal>
    </Body>
  );
};
