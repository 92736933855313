/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Layout } from "antd";
import styled from "styled-components";

const AppLayoutContent = styled(Layout.Content)`
  
  
  background: white;
  max-height:100%;
  a,
  .topnavigation .ant-breadcrumb li:last-child a,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .language-lists .ant-breadcrumb .ant-breadcrumb-link span a,
  .language-lists .ant-breadcrumb .ant-breadcrumb-link span:last-child,
  .language-lists .ant-breadcrumb span:last-child a,
  .ant-breadcrumb span,
  .ant-breadcrumb .ant-breadcrumb-link a,
  .ant-list .ant-breadcrumb .ant-breadcrumb-link a,
  
  .home-news-carousel .slick-list .slick-track .slick-slide .ant-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title,
  .home-commitments .ant-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title,

  .statistical-commission-terms-of-reference .ant-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description, .webcast-recordings .ant-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description,
  .documents-list .ant-breadcrumb > span:last-child a,
  .milestones-list .ant-timeline .ant-timeline-item .ant-timeline-item-head-blue,
  .publication-past-sessions .ant-card .ant-card-body h4,
  .datafornow .capacity-development-sidenav-list .sidebar-external-link-icon,
  .datafornow .country-card-btuuton,
  .common-menu .capacity-development-sidenav-list .sidebar-external-link-icon,
  .CapacityDevelopment .capacity-development-sidenav-list .sidebar-external-link-icon,
  .CapacityDevelopment .country-card-btuuton,
  .nqaf-main-layout .topnavigation .ant-breadcrumb li:last-child,
  .nqaf-main-layout .topnavigation .ant-breadcrumb li:last-child a {
    color: #283460;
  }
  .milestones-list .ant-timeline .ant-timeline-item .ant-timeline-item-head-blue {
    border-color: #283460;
  }
  a:not([href]),
  .language-lists .ant-breadcrumb > span:last-child a,
  .nqaf-mobile-menu-drawer-content .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu-dark .ant-menu-item a.statistical-commission,
  .rc-inventory-search a:not([href]):not([class]),
  .ant-menu-item-selected, 
  .language-lists .ant-breadcrumb > li:last-child {
    color: #283460 !important;
  }
  // .ant-menu-title-content:hover{color:#fff}
  .ant-tabs-ink-bar,
  .ant-modal .ant-modal-content .ant-modal-header {
    background-color: #283460;
  }
  .mobile-menu-drawer-content .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu-dark .ant-menu-item a.statistical-commission {
    background-color: #283460!important;
  }
  .ant-layout-footer {
    margin: auto;
    padding: auto;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .drawerSider {
    height: calc(100vh - 173px)!important;
  }

  .ant-carousel .slick-slider .slick-dots li.slick-active button {
    border: 3px solid #283460;
    background-color: #283460;
  }
  .ant-carousel .slick-slider .slick-dots li button {
    width: 12px;
    height: 12px;
    opacity: 1;
    border-radius: 50%;
    border:none;
    background-color: #d9d9d9;
  }

  @media(max-width:1280px){
    .drawerSider {
        height: calc(100vh - 226px)!important;
    }
  }
  @media(max-width:820px){
    .drawerSider {
        height: calc(100vh - 140px)!important;
    }
  }
`;

export default AppLayoutContent;
