import { Col, Row } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../../../containers/Language';

const HeaderRow = styled(Row)`
@media (max-width: 820px){
    > .ant-col.ant-col-xs-12 {
        flex:none;
        padding-right: 0 !important;
        width:40px!important;
        margin-bottom:14px;
        .dc-logo, .und-logo{
            display: block;
            text-align: right;
            .logo {
                padding-right: none!important;
                width: unset;
                border-right: none!important;
            }
            span {
                font-size: 10px;
                letter-spacing: -0.7px;
            }
            h3 {
                color: #000000;
                font-size: 38px;
                font-style: normal;
                font-weight: 700;
                line-height: 20.228px;
                
              }
        }
        .und-logo .logo {
            width:201px!important;
            padding-right:0!important;
        }
        .menu-icon {
            margin:0 16px 7px 0;
            position: static;
            width: 24px !important;
            height: 40px !important;
        }
    }
    .mobile-wrap .logo-wrap{
        overflow: hidden;
        width: 50px;
        display: inline-block !important;
        border: none !important;
        margin-left: 55px;
        .logo {
            height: 40px; 
        }
    }
    .mobile-hide {
        display:none;
    }
}
@media (min-width: 820px){
    .desktop-hide {
        display:none;
    }
    .dc-logo, .und-logo {
        justify-content: flex-end;
    }
}
    .dc-logo img{ 
        width:auto!important;
        border-right: none;
        padding-right:0;
    }
`

export default class Global extends Component {
    addPlaceholder = () => {
        document.getElementById('gsc-i-id1').setAttribute('placeholder', 'Search');
    };

    render() {
        return (
            <HeaderRow gutter={{ xs: 32, sm: 32, md: 32, lg: 32 }}>
                <Col xs={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                    <Row>
                        <Col className="gutter-row mobile-wrap">
                            {this.props.children}
                            <div // href={routeResourceCatalogPathConstants.HOME_PATH} 
                            className="mobile-hide">
                                <img src="./images/datacommons/UN_logo2.svg" alt="UN logo" className="logo" />
                            </div>
                        </Col>
                        <Col className="gutter-row mobile-hide">
                            <h3 className="main-title"><Text tid="GlobalH3" /></h3>
                            <div className="sub-title">
                                <span><Text tid="GlobalSpan1" /></span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                    <div className="und-logo">
                        <img src="./images/datacommons/undata-logo-color.svg" alt="UN Data logo" className="logo desktop-hide" />
                    </div>
                    <div className="dc-logo">
                        <img src="./images/datacommons/undata_logo.svg" alt="UN Data logo" className="logo mobile-hide" />
                    </div>
                </Col>
            </HeaderRow>
        );
    }
}
