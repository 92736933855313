// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.branding-link-container {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  flex-shrink: 0;
}

.branding-link-container a {
  display: flex;
  align-items: center;
  color: #151515;
  text-decoration: none;
}

.branding-link-container img {
  width: 100px;
  align-self: flex-end;
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/templates/DataCommons/components/shared/components.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":["/**\n * Copyright 2023 Google LLC\n *\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.branding-link-container {\n  display: flex;\n  flex-direction: row;\n  font-weight: 500;\n  flex-shrink: 0;\n}\n\n.branding-link-container a {\n  display: flex;\n  align-items: center;\n  color: #151515;\n  text-decoration: none;\n}\n\n.branding-link-container img {\n  width: 100px;\n  align-self: flex-end;\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
