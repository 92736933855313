import { Collapse } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROOT_TOPIC } from "../../utils/constants";
import { Divider } from "../shared/components";
import { Article } from "../shared/goals/GoalOverview";
import { IndicatorHeader, StoryContent } from "../shared/goals/IndicatorOverview";
const { Panel } = Collapse;

export const StoryPanel = styled(Panel)<{
    $color?: string;
  }>`
    ${({ $color }) => `
  border-top: 1px solid ${$color}!important;
    .ant-collapse-header{
        padding: 6px 40px 6px 0!important;
        font-weight: 600;
    }
    .ant-collapse-header-text{
        font-size:14px;
    }
  `}
  `;
const StoryAccordeon = styled(Collapse)`
background: #fff;
padding: 1rem;
border: none;
border-radius: 0 0 8px 8px;
.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
  right:0.5rem!important;
}
.ant-collapse-item {
  border-bottom: none!important;
}
.ant-collapse-content,
.ant-collapse-item:first-child {
  border-top: none!important;
}
.ant-collapse-content-box {
    padding: 0 0;
    @media(max-width: 992px){
        padding: 16px 0;
        > * {
            padding: 0;
        }
    }
}
`
export const StoryHeader = styled(IndicatorHeader)`
    margin: 16px -24px;
    @media(max-width: 992px) {
      margin: 16px -16px;
      h4{
        padding: 0 16px;
      }
    }
`

type KeyedObject<T> = {
  [key: string]: T;
};

export const keyBy = <T extends Record<string, any>>(array: T[], key: keyof T): KeyedObject<T> => {
  return array && array.reduce((acc, obj) => {
    const keyValue = obj[key];
    if (typeof keyValue === 'string' || typeof keyValue === 'number') {
      acc[keyValue as string] = obj;
    }
    return acc;
  }, {} as KeyedObject<T>);
};


const DataStories: React.FC<{
    color: string;
    content: Article[];
    goalName?: string;
    goalNumber?: string | number;
  }> = ({ color, content, goalName, goalNumber }) => {
    const onChange = (key: string | string[]) => {
        // console.log(key);
    };
    
    return <>
        <StoryAccordeon 
        accordion
        expandIconPosition="end"
        onChange={onChange}>
            {content && content.map((story, i) => {
              let breadcrumbs = story?.tags?.sort((a, b) => {
                const aIncludes = a.dcid.includes(`_${goalNumber}`);
                const bIncludes = b.dcid.includes(`_${goalNumber}`);
                
                if (aIncludes && !bIncludes) return -1;
                if (!aIncludes && bIncludes) return 1;
                return 0;
            }).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
              // let breadcrumbs = story?.tags?.filter(a=>a.dcid.includes(`_${goalNumber}`)).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
              return <StoryPanel $color={color} header={story.name} key={i}>
                <StoryHeader color={color}>
                    {breadcrumbs?.length && <StoryBreadcrumbs breadcrumbs={breadcrumbs} goalName={goalName}/>}
                    <h4>{story.name}</h4>
                    <Divider color={color}/>
                </StoryHeader>
                <StoryContent content={story}/>
            </StoryPanel>})}
        </StoryAccordeon>
    </>
  }
  export default DataStories

export const StoryBreadcrumbs: React.FC<{
    breadcrumbs: string[];
    goalName?: string;
  }> = ({ breadcrumbs, goalName }) => {
    return <ul>
    {breadcrumbs[0] && <li><Link to={`?v=${ROOT_TOPIC}_${breadcrumbs[0]}`}><span>{breadcrumbs[0]}</span> {goalName}</Link></li>}
    {breadcrumbs[1] && <li><Link to={`?v=${ROOT_TOPIC}_${breadcrumbs[1]}`}><span>{breadcrumbs[1]}</span></Link></li>}
    {breadcrumbs[2] && <li><Link to={`?v=${ROOT_TOPIC}_${breadcrumbs[2]}`}><span>{breadcrumbs[2]}</span></Link></li>}
</ul>
  }