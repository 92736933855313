import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { commonConstants } from '../../../helper/Common/ResourceCatalog/CommonConstants';
import TopNavigation from './menu.js';

const BurgerMenu = styled(Button)`
left: 0;
width: 60px!important;
height: 44px!important;
color: #000!important;
border: none!important;
margin-right: 16px;
@media (min-width: 820px){
    display:none;
}
`;
const MenuDrawer = styled(Drawer)`
.ant-drawer-content-wrapper {
    top:45px;
    .ant-drawer-content {
        border-radius: 0 8px;
    }
    .ant-drawer-header-close-only {
        padding-top:14px;
        padding-bottom:14px;
        .ant-drawer-close{
            padding: 14px;
            margin: -14px;
        }
    }
}
`


export default class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: this.props.visible,
            showDrawer: commonConstants.SETFALSE,
        };
    }
    showDrawer = () => {
        this.setState({ showDrawer: commonConstants.SETTRUE })
    };

    onClose = () => {
        this.setState({ showDrawer: commonConstants.SETFALSE })
    };

    render() {
        return (this.state.isVisible ? <Fragment>
            <BurgerMenu type="text" onClick={this.showDrawer} className="menu-icon">
                <MenuOutlined />
            </BurgerMenu>
            <MenuDrawer
                placement="left"
                width={329}
                closable={commonConstants.SETTRUE}
                onClose={this.onClose}
                visible={this.state.showDrawer}
                className="mobile-menu-drawer-content"
            >
                <TopNavigation onClick={this.onClose} />
            </MenuDrawer>
        </Fragment> : <Fragment></Fragment>)
    }
}
