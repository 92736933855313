
import { Dropdown, Menu } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
type child = {
  name: string,
  to?: string;
}
type Props = {
  items?: child[];
  children?: string;
};
const BreadcrumbLayout = styled.span`
    color: #7C7C7C;
    font-size: 14px;
    display: flex;
    padding: 16px 0;
    position: relative;
    align-items: center;
    a {
      padding-right:8px;
    }
    .material-icons {
      font-size:22px;
      margin-right:8px
    }
    .nav {
      display:flex;
    }
    .nav-item {
      display: flex;
      align-items: center;
    }
    @media(max-width:767px){
      .nav .nav-item:not(.last){
        display: none;
      }
    }

`
const MobileToggle = styled.button`    
  border: none;
  width: 22px;
  min-width: 22px;
  border-radius: 50%;
  line-height: 10px;
  padding: 0;
  margin-right: 8px;
  background: transparent;
  -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none; 
  overflow: hidden;
  transition: all .2s;
  :hover{
    background: #f2f2f2;
  }

  @media(min-width:767px){
    display:none;
  }
`
const MobileMenu = styled(Menu)`
  width: calc(100vw - 240px);
  width: 540px;
  border-radius: 7px !important;

  @media(max-width: 560px){
    width: calc(100vw - 32px);
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .ant-dropdown-menu-item {
    padding: 4px 12px 4px 8px;
    .material-icons {
      margin-right: 8px;
      font-size: 20px;
      color: #4D4D4D;
    }
    
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    background: #e5e5e5;
  }
`
const AppBreadcrumb = ({ items, children }: Props) => {


  const menu = (
    <MobileMenu>
      <Menu.Item key={0}>
        <span className="material-icons">radio_button_unchecked</span>
        <Link to={`${routeDataCommonsConstants.HOME_PATH}`}>Home</Link>
      </Menu.Item>
      {
        items && items.map((item, i) => {
          return <Menu.Item key={i + 1}>
            <span className="material-icons">radio_button_unchecked</span>
            <Link to={`${item.to}`}>{item.name}</Link>
          </Menu.Item>
        })
      }
    </MobileMenu>
  );

  const [visible, setVisible] = useState(false)
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  return (
    <BreadcrumbLayout>
      {menu &&
        <Dropdown overlay={menu} visible={visible} onVisibleChange={handleVisibleChange} trigger={['click']}>
          <MobileToggle onClick={() => { setVisible(true); }}>
            <span className="material-icons">
              more_horiz
            </span>
          </MobileToggle>
        </Dropdown>
      }
      <div className='nav'>
        <Link key={0} className="nav-item" to={`${routeDataCommonsConstants.HOME_PATH}`}>Home</Link>
        {items && items.map((item, i) => {
          return <div className="nav-item" key={i + 1}><span className="material-icons">chevron_right</span> <Link to={`${item.to}`}>{item.name}</Link> </div>
        })}
        <div className="nav-item last" key={999}><span className="material-icons">chevron_right</span> {children}</div>
      </div>
    </BreadcrumbLayout>
  );
};
export default AppBreadcrumb;
