export const commonConstants = {
    DEFAULT_Variable: [],
    BASE_URL: process.env.PUBLIC_URL,
    SETTRUE: true,
    SETFALSE: false,
    CROSS_CUTTING_ISSUE: 'CrossCuttingIssue',
    RESOURCE_TYPE: 'Resource_type',
    COUNTRY_OR_ORGANIZATION: 'CountryOrOrganization',
    DOMAIN: 'Domain',
    LANGUAGE: 'Language',
    TITLE: 'Title',
    ddlDomain: 'domain',
    SEL_CROSS_CUTTING_ISSUE: 'Select cross cutting issue',
    SEL_RESOURCE_TYPE: 'Select types',
    SEL_COUNTRY_OR_ORGANIZATION: 'Select Country or Organization',
    SEL_DOMAIN: 'Select domain',
    SEL_LANGUAGE: 'Select language',
    SEARCH: 'Search',
    CurrentPage: 'CurrentPage',
    PageSize: 'PageSize',
    RandonString: 'RandonString',
    DefaultInventoryRoute: '/1/default',
    SORTOPTIONS: [
        { label: 'Select...', value: '' },
        { label: 'SortNameAsc', value: 'Name_asc' },
        { label: 'SortNameDsc', value: 'Name_desc' },
        { label: 'SortDateAsc', value: 'Date_asc' },
        { label: 'SortDateDsc', value: 'Date_desc' },
    ],

    VIEW_RECORD_MAX: 6,
    DEFAULT_SORT_VALUE: '',
    TWITTER_URL: 'https://twitter.com/UNStats',
    YOUTUBE_URL: 'https://www.youtube.com/user/UNStats',
    COPYRIGHT: 'https://www.un.org/en/about-us/copyright',
    FRAUD_ALERT: 'https://www.un.org/en/about-us/fraud-alert',
    PRIVACY_NOTICE: 'https://www.un.org/en/about-us/privacy-notice',
    TERMS_OF_USE: 'https://www.un.org/en/about-us/terms-of-use',
    READMORE_MAX: 2,

    DEFAULT_Image: './images/DefaultImage.png',
    DEFAULT_ImagePath: './images/resourceCatalog/thumbnails/',
    EACHPAGESIZE: 6,
    SHOWPAGESIZECHANGER: true,
    PAGESIZEOPTIONS: ["6", "12", "18", "24", "30", "36", "42", "48", "54", "60"],
    CURRENTPAGE: 1
};
