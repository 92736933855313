import { CaretDownOutlined } from "@ant-design/icons";
import { Col, Layout, Row, Select } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
//import topicsData from "../../config/sidebar_merged.json";
import { useEffect, useState } from "react";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";

const { Option } = Select;
//const topics = topicsData.sort((a, b) => a.label.localeCompare(b.label));

const AreaTop = styled.div`
  background-image: url("./images/datacommons/search-background.png");
  background-size: 100% auto;
  padding: 0 2rem 1rem;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 60%;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 0 1rem;
    p {
      width: 100%;
    }
  }
`;

const AreaBody = styled.div`
.ant-select{
  width: 300px;
}
@media only screen and (max-width: 800px) {
.ant-select {
    width: 100%;
  }
}

p {
    color: #616161);
    font-size: 12px;
    padding: 0.5rem 0 1rem 1rem;
}
`;

const AreaCard = styled.div`
  border-radius: 8px;
  border: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  span {
    display: block;
    padding: 2rem;
    background-color: #f1f1f1;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    padding: 20px;
  }
`;

const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
    height: 38px;
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    color: black;
    padding-top: 3px !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 1rem;
  }
`;

const ThematicAreas: React.FC = () => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const history = useHistory();

  const goToPartner = (value: { value: string }) => {
    history.push(`${routeDataCommonsConstants.THEMATIC_AREAS}/${value}`);
  };

  return (
    <>
      <AppLayoutContent>
        <AreaTop>
          <Layout className="container" style={{ backgroundColor: "transparent", padding: "0 1rem 1rem" }}>
            <AppBreadcrumb>Thematic Areas</AppBreadcrumb>
            <span style={{ color: "#7C7C7C", fontSize: "14px", display: "block", padding: "0 0 20px" }}></span>
            <h3>Thematic Areas</h3>
            <p>
              Gain valuable insights into the challenges and progress shaping our interconnected world, with datasets and visualizations available across 12
              thematic areas.{" "}
            </p>
          </Layout>
        </AreaTop>
        <Layout style={{ padding: "2rem 1rem" }} className="container">
          <AreaBody>
            {topics && (
              <>
                <CountrySelectContainer>
                  <Select
                    suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                    optionLabelProp="label"
                    onChange={goToPartner}
                    placeholder="Thematic Areas | All"
                  >
                    {topics.map((item) => (
                      <Option value={item.id} label={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </CountrySelectContainer>
                <Row style={{ paddingTop: "2rem" }} gutter={[32, 48]}>
                  {topics
                    .filter((v) => v.type != "placeholder")
                    .map((item) => (
                      <Col sm={24} md={12} lg={8} flex="1 0 100%">
                        <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${item.id}`}>
                          <AreaCard>
                            <span>
                              <img height={100} src={item.icon} />
                            </span>
                            <h3>{item.label}</h3>
                          </AreaCard>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </>
            )}
          </AreaBody>
        </Layout>
      </AppLayoutContent>
    </>
  );
};

export default ThematicAreas;
