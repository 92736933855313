import React from "react";
import styled from "styled-components";
import { Layout, Select, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { routeDataCommonsConstants} from "../../../../helper/Common/RoutePathConstants.js";

const Body = styled.div`
border: solid 1px #efefef;
border-radius: 8px;
background: #FFF;
box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.10);

img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

p {
    color: #616161);
    font-size: 12px;
    padding: 0.5rem 0 1rem 1rem;
}
`;

const Frame = styled.div`
    
`;

export const Box: React.FC<{ props: object, paddingClass: string | undefined }> = ({
    props,
    paddingClass,
  }) => {
    const padding = typeof paddingClass == 'undefined' ? '1rem' : paddingClass;
    return (
    <Body style={{borderTop: `8px solid ${props.color}`}}>
        <Frame style={{padding: padding}}>
            <div style={{textAlign: 'left', padding: padding}}>
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
        </Frame>
    </Body>
    )
  }
