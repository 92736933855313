/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { gray } from "@ant-design/colors";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Breadcrumb, Col, Dropdown, Grid, Input, Layout, Row, Spin } from "antd";
import _ from "lodash";
import { parseToRgb } from "polished";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useStoreState } from "../../state";
import { FOOTNOTE_CHAR_LIMIT, QUERY_PARAM_VARIABLE, ROOT_TOPIC } from "../../utils/constants";
import "./components.css";
// @ts-ignore
import { routePathConstants } from "../../../../../src/helper/Common/RoutePathConstants";

const useBreakpoint = Grid.useBreakpoint;
const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .info {
    align-self: flex-end;
    color: ${gray[3]};
    font-size: 11px;
    font-weight: 500;
    margin: 0 1.2rem 0rem;
    text-transform: uppercase;
  }
  .search {
    position: relative;

    input {
      border-radius: 2rem !important;
      padding-right: 2.25rem;
    }

    .anticon {
      color: ${gray[6]};
      cursor: pointer;
      position: absolute;
      right: 0.5rem;
      padding: 13px;
      top: 0;
      z-index: 100;
    }

    .ant-spin {
      cursor: not-allowed;
      position: absolute;
      right: 0.85rem;
      top: 0.7rem;
      z-index: 100;

      .ant-spin-dot-item {
        background: ${gray[5]};
      }
    }
  }
`;

const SearchInput = styled(Input)`
  border-radius: 2rem;
  padding: 0.5rem 1rem;
`;

/**
 * Header & Footer branding link
 */
export const BrandingLink: React.FC = () => {
  return (
    <div className="branding-link-container">
      <a href="https://datacommons.org" target="_blank" rel="noopener noreferrer">
        Powered by Google's <img className="logo-secondary-image" src="./images/datacommons/dc-logo.png" />
      </a>
    </div>
  );
};

/**
 * Search bar for the search page and search results page
 */

export const SearchBar: React.FC<{
  initialQuery?: string;
  isSearching?: boolean;
  onSearch: (query: string) => void;
  placeholder?: string;
}> = ({ initialQuery, isSearching, onSearch, placeholder }) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  const onInputFocus = () => {
    //if (query === initialQuery) setQuery("");
  };

  const onInputBlur = () => {
    if (query === "") setQuery(initialQuery ?? "");
  };

  return (
    <SearchInputContainer>
      {/*<div className="info">Early Preview</div>*/}
      <div className="search">
        <SearchInput
          className="-dc-search-bar"
          placeholder={placeholder ? placeholder : 'For example, "Access to Clean Energy in Afghanistan"'}
          size="large"
          value={query}
          disabled={isSearching}
          onChange={(e) => setQuery(e.currentTarget.value)}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onPressEnter={() => {
            onSearch(query);
          }}
        />
        {isSearching ? (
          <Spin />
        ) : (
          <SearchOutlined
            onClick={() => {
              onSearch(query);
            }}
          />
        )}
      </div>
    </SearchInputContainer>
  );
};

export const MainLayoutContent = styled(Layout.Content)`
  padding: 0 16px 16px;
  @media (min-width: 992px) {
    padding: 0 24px 16px;
  }
`;

/**
 * Content card for Goals / Countries page
 */
export const ContentCard = styled.div`
  margin: 0 0 2rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 992px) {
    margin: 0 0 2rem;
    padding: 1.5rem 1.5rem;
  }
  a {
    color: #5f94e5;
  }
`;

export const InvertableCard = styled(ContentCard)<{ color: string; invert?: boolean }>`
  ${({ color, invert }) =>
    invert
      ? `
    background: ${color}20;
    * {
      // color:#fff!important;
      }
    .-dc-target-header {
      // border: 2px solid #fff;
    }
  `
      : `
  `}
`;
export const ContentCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  img {
    width: 5rem;
    height: 5rem;
    margin-right: 1.5rem;
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`;
export const ContentCardBody = styled.div`
  background: #fff;
  margin-left: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
  }
  @media (min-width: 992px) {
    padding: 1rem 9rem 1rem 3rem;
    margin-left: 6rem;
  }
`;

// Country selection dropdown
const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
  }
  .ant-select-selection-placeholder {
    color: black;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 15px;
    font-size: 1rem;
  }
  @media only screen and (max-width: 800px) {
    width: 100% !important;
    .ant-select {
      width: 100% !important;
    }
  }
`;
const CountrySelectNoResults = styled.div`
  padding: 5px 12px;
`;

export const CountrySelect: React.FC<{
  placeholder?: string;
  setSelectedPlaceDcid: (selectedPlaceDcid: string) => void;
  style?: Record<string, string>;
}> = ({ setSelectedPlaceDcid, placeholder, style }) => {
  const [isFocused, setIsFocused] = useState(false);
  const countries = useStoreState((s) => s.countries.dcids.map((dcid) => s.countries.byDcid[dcid]));
  const regions = useStoreState((s) => s.regions.dcids.map((dcid) => s.regions.byDcid[dcid]));
  const options = [countries, regions].flat().map((place) => ({ value: place.name, dcid: place.dcid }));
  options.sort(({ value: value1 }, { value: value2 }) => value1.localeCompare(value2));
  const containerWidth = style && style.width ? style.width : "fit-content";
  const [value, setValue] = useState("");

  useEffect(() => {});

  return (
    <CountrySelectContainer width={containerWidth}>
      <AutoComplete
        className="-dc-place-search"
        size="large"
        value={isFocused ? value : ""}
        style={style || { width: 225 }}
        options={options}
        placeholder={placeholder || "Select a country or area"}
        defaultActiveFirstOption={true}
        notFoundContent={<CountrySelectNoResults>No results found</CountrySelectNoResults>}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option!.dcid.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        onChange={(value, option) => {
          setValue(value);
          if ("dcid" in option) {
            setSelectedPlaceDcid(option.dcid);
            setValue("");
          }
        }}
      />
      <CaretDownOutlined />
    </CountrySelectContainer>
  );
};

// Header "Place" Card for top of Country/Region Pages
const PlaceCard = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
`;

const PlaceCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
  overflow: hidden;
  @media (min-width: 992px) {
    gap: 24px;
    margin: 24px;
  }
`;

const PlaceTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  li {
    display: flex;
  }
  .ant-breadcrumb-link a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const UserMessage = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;
  padding: 16px 24px;
  strong {
    padding-left: 16px;
  }
`;

const PlaceTitleRow = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const PlaceHeaderCard: React.FC<{
  placeNamesAndDcids: { name: string; dcid: string }[];
  hideBreadcrumbs?: boolean;
  hidePlaceSearch?: boolean;
  isSearch: boolean;
  setSelectedPlaceDcid: (selectedPlaceDcid: string) => void;
  userMessage?: string;
  variableDcids: string[];
  topicNames?: string;
}> = ({ placeNamesAndDcids, hideBreadcrumbs, hidePlaceSearch, isSearch, setSelectedPlaceDcid, userMessage, variableDcids, topicNames }) => {
  // get breadcrumbs from current location
  const location = useLocation();
  const topics = useStoreState((s) => variableDcids.filter((dcid) => dcid in s.topics.byDcid).map((dcid) => s.topics.byDcid[dcid]));
  const parentVariables = useStoreState((s) => {
    const parentDcids: string[] = [];
    if (topics.length !== 1) {
      return [];
    }
    let currentVariableDcid = variableDcids[0];
    const BREADCRUMB_LIMIT = 10;
    let breadcrumbIndex = 0;
    while (currentVariableDcid !== ROOT_TOPIC) {
      // This avoids the possibility of an infinite loop
      breadcrumbIndex++;
      if (breadcrumbIndex > BREADCRUMB_LIMIT) {
        break;
      }
      if (!(currentVariableDcid in s.topics.byDcid)) {
        break;
      }
      currentVariableDcid = s.topics.byDcid[currentVariableDcid].parentDcids[0];
      parentDcids.unshift(currentVariableDcid);
    }
    return parentDcids.map((parentDcid) => s.topics.byDcid[parentDcid]);
  });
  const breakpoint = useBreakpoint();
  const placeNames = placeNamesAndDcids.map((p) => p.name);
  const shouldHideBreadcrumbs = hideBreadcrumbs || (topics.length === 1 && topics[0].dcid === ROOT_TOPIC);
  // show topic names only if on search and there is a place found
  const shouldShowTopicNames = isSearch && topicNames && !_.isEmpty(placeNamesAndDcids);
  const showNoTopicsFoundMessage = isSearch && placeNamesAndDcids.length > 0 && !topicNames;
  return (
    <PlaceCard>
      <PlaceCardContent>
        {userMessage && (
          <UserMessage>
            <img width={80} src="./images/datacommons/low-confidence.png" />
            <strong>{userMessage}</strong>
          </UserMessage>
        )}
        {showNoTopicsFoundMessage && (
          <UserMessage>
            No topics found. Explore SDG topics for{" "}
            {placeNamesAndDcids.map((p, i) => (
              <Link key={i} to={`${routePathConstants.DATA_COMMONS}countries?p=${p.dcid}`}>
                {p.name}
                {i === placeNamesAndDcids.length - 1 ? "" : ", "}
              </Link>
            ))}
            .
          </UserMessage>
        )}
        {hidePlaceSearch || isSearch ? (
          <PlaceTitle>
            {placeNames.join(", ")}
            {shouldShowTopicNames ? ` • ${topicNames}` : ""}
          </PlaceTitle>
        ) : (
          <PlaceTitleRow>
            {!breakpoint.xs && <PlaceTitle>{placeNames.join(", ")}</PlaceTitle>}
            <CountrySelect setSelectedPlaceDcid={setSelectedPlaceDcid} placeholder={breakpoint.xs && placeNames.length > 0 ? placeNames[0] : undefined} />
          </PlaceTitleRow>
        )}
        {!shouldHideBreadcrumbs && (
          <StyledBreadcrumb>
            {[...parentVariables, ...(topics.length === 1 ? topics : [])]
              .filter((v) => v)
              .map((v, i) => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set(QUERY_PARAM_VARIABLE, v.dcid);
                return (
                  <Breadcrumb.Item key={i}>
                    <Link to={`${location.pathname}?${searchParams.toString()}`} title={v.name}>
                      {v.name}
                    </Link>
                  </Breadcrumb.Item>
                );
              })}
          </StyledBreadcrumb>
        )}
      </PlaceCardContent>
    </PlaceCard>
  );
};

// Headline callouts for each of the indicators
const HeadlineContainer = styled.div<{ $backgroundColor: string }>`
  background-color: ${(p) => {
    const rgb = parseToRgb(p.$backgroundColor);
    return `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0.1);`;
  }};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 16px;
`;

const HeadlineText = styled.div`
  color: #444746;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 1rem;
`;

const HeadlineImage = styled.img`
  box-shadow: 0px 0px 6px rgba(3, 7, 18, 0.03), 0px 1px 14px rgba(3, 7, 18, 0.05);
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: white;
`;

const HeadlineLink = styled.div`
  cursor: pointer;
  margin-left: auto;
  width: fit-content;

  a {
    font-size: 1rem;
    line-height: 1rem;
  }

  .material-icons-outlined {
    color: #5b92e5;
    font-size: 1rem;
    line-height: 1rem;
    vertical-align: middle;
  }
`;

export const HeadlineTile: React.FC<{
  backgroundColor: string;
  indicator: string;
}> = ({ backgroundColor, indicator }) => {
  const indicatorHeadlines = useStoreState((s) => s.indicatorHeadlines);
  const headlineData = indicatorHeadlines.byIndicator[indicator];

  if (!headlineData) {
    return <></>;
  }
  const textSpanSize = headlineData.images.length === 1 ? 12 : 24;
  return (
    <HeadlineContainer $backgroundColor={backgroundColor}>
      <Row gutter={16}>
        {headlineData.headline && (
          <Col xs={24} sm={24} md={24} lg={24} xl={textSpanSize}>
            <HeadlineText>{headlineData.headline}</HeadlineText>
          </Col>
        )}

        {headlineData.images.map((url, i) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={12} key={i}>
            <HeadlineImage src={url} />
          </Col>
        ))}
      </Row>
      <HeadlineLink>
        <a href={headlineData.link} target="_blank" rel="noreferrer">
          Read more <span className="material-icons-outlined">open_in_new</span>
        </a>
      </HeadlineLink>
    </HeadlineContainer>
  );
};

// Header for a target
const TargetIdBox = styled.div<{ color: string }>`
  border-radius: 16px;
  border: 2px solid ${(p) => p.color};
  color: ${(p) => p.color};
  padding: 2px 10px;
  width: auto;
  min-width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
`;

const TargetText = styled.span<{ color: string }>`
  color: ${(p) => p.color};
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  width: calc(100% - 140px);
  height: 100%;
`;
const TargetBody = styled.div`
  display: flex;
  a {
    width: calc(100% - 0px);
    span {
      width: calc(100% - 20px);
      padding-right: 20px;
    }
  }
`;

const StatusIcon = styled.span`
  width: 21px;
  height: 21px;
  border-radius: 100%;
  display: inline-block;
`;
const StatusBody = styled.div`
  background: #fff;
  padding: 12px 20px;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  div {
    display: flex;
    padding: 0.5rem 0;
  }
  span {
    margin-right: 0.75rem;
  }
`;
const StatusBlock = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
  a {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 21px;
  }
`;
// Display SGD progress
const GoalProgress: React.FC<{
  status: number;
}> = ({ status }) => {
  const statuses = {
    1: { name: "On Track", color: "#5EAA22" },
    2: { name: "Moderate progress", color: "#FEC65A" },
    3: { name: "Stagnation or Regression", color: "#E5243B" },
  };
  return (
    <Dropdown
      dropdownRender={(menu) => (
        <StatusBody>
          <b>SDG Progress</b>
          <div>
            <StatusIcon style={{ backgroundColor: statuses[status].color }} /> {statuses[status].name}
          </div>
          <a href="#">Read More</a>
        </StatusBody>
      )}
      trigger={["click"]}
    >
      <StatusBlock>
        <a onClick={(e) => e.preventDefault()}>
          <StatusIcon style={{ backgroundColor: statuses[status].color }} />
        </a>
      </StatusBlock>
    </Dropdown>
  );
};
export const TargetHeader: React.FC<{ color: string; target: string }> = ({ color, target }) => {
  const targetDescriptions = useStoreState((s) => s.targetText);

  const location = useLocation();
  const targetText = targetDescriptions.byTarget[target];
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(QUERY_PARAM_VARIABLE, `${ROOT_TOPIC}_${target}`);
  const exploreUrl = location.pathname + "?" + searchParams.toString();
  const isGoal = location.pathname.includes("/goals");
  return (
    <TargetBody>
      <TargetIdBox className={`-dc-target-header -dc-target-header-${target}`} color={color}>
        {target}
      </TargetIdBox>
      <Link to={exploreUrl}>
        <TargetText color={color}>{targetText}</TargetText>
      </Link>
      {/* {isGoal && <GoalProgress status={Math.floor(Math.random() * 3) + 1}/>} */}
    </TargetBody>
  );
};

export const TopicHeader: React.FC<{ color: string; target: string }> = ({ color, target }) => {
  const targetDescriptions = useStoreState((s) => s.targetText);
  const topics = useStoreState((s) => s.topics);

  const location = useLocation();
  const targetText = topics.byDcid[`${ROOT_TOPIC}_${target}`]?.name;
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(QUERY_PARAM_VARIABLE, `${ROOT_TOPIC}_${target}`);
  const exploreUrl = location.pathname + "?" + searchParams.toString();

  return (
    <TargetBody>
      <TargetIdBox className={`-dc-target-header -dc-target-header-${target}`} color={color}>
        {target}
      </TargetIdBox>
      <Link to={exploreUrl}>
        <TargetText color={color}>{targetText}</TargetText>
      </Link>
      {/* <GoalProgress status={Math.floor(Math.random() * 3) + 1}/> */}
    </TargetBody>
  );
};

// Red Dividing line under target headers
export const Divider = styled.div<{ color: string }>`
  border: 1px solid ${(p) => p.color};
  margin: 40px -16px;
  @media (min-width: 992px) {
    margin: 40px -24px 40px -24px;
  }
`;

export const ExploreBtn = styled.div`
  text-align: center;
  padding-bottom: 2rem;
  > button {
    background: #283460;
    color: #fff;
    border-radius: 36px;
    line-height: 32px;
    height: 36px;
    padding: 0 16px;
    border: solid 1px #283460;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: 600;
    margin: 0 auto;
    &:hover {
      background: #fff;
      color: #283460;
      border-color: #283460 !important;
    }
  }
`;

// Footnotes
const MAP_DISCLAIMER_TEXT = `
The boundaries and names shown and the designations used on this and other maps throughout this publication do not imply official endorsement or acceptance by the United Nations.
`;

const DATACOMMONS_INFO_TEXT = `
UN Data integrates authoritative data from across the UN System into a public repository with a user-friendly interface and advanced natural language search functionality. This data analysis and exploration tool is the product of an ongoing UN Statistics Division effort supported by Google’s Data Commons and funded by Google.org, with the ultimate goal of making the UN’s authoritative data more accessible to the public.
`;

export const FootnotesContainer = styled.div`
  margin: 24px 0px;
  @media (min-width: 992px) {
    padding-left: 0.5rem;
    padding-left: 0.5rem;
  }
`;

export const Footnote = styled.div`
  color: grey;
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

export const StyledMarker = styled.div`
  font-size: 1rem;
  margin: 0 4px;
`;

export const FootnoteDivider = styled.hr`
  color: grey;
  margin-bottom: 24px;
`;

export const Footnotes: React.FC = () => {
  return (
    <FootnotesContainer>
      <FootnoteDivider></FootnoteDivider>
      {/* <Footnote>
        <div>{DATACOMMONS_INFO_TEXT}</div>
      </Footnote> */}
      <Footnote>
        <StyledMarker>*</StyledMarker>
        <div>{MAP_DISCLAIMER_TEXT}</div>
      </Footnote>
    </FootnotesContainer>
  );
};

// Footnotes for the chart tiles
export const ChartFootnoteContainer = styled.div`
  margin: 0 24px 24px 14px;
  font-size: 0.8rem;
`;

export const ShowMoreToggle = styled.span`
  cursor: pointer;
  color: var(--link-color);
`;

export const ChartFootnote: React.FC<{ text: string | undefined }> = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false);
  if (!text) {
    return <></>;
  }
  const hideToggle = text.length < FOOTNOTE_CHAR_LIMIT;
  const shortText = text.slice(0, FOOTNOTE_CHAR_LIMIT);
  return (
    <ChartFootnoteContainer>
      {hideToggle || showFullText ? text : `${shortText}...`}
      <ShowMoreToggle onClick={() => setShowFullText(!showFullText)}>{!hideToggle && (showFullText ? " Show less" : "Show more")}</ShowMoreToggle>
    </ChartFootnoteContainer>
  );
};
