/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { QUERY_PARAM_QUERY, SAMPLE_NL_QUERY } from "../../utils/constants";
import { SearchBar } from "../shared/components";
import {
  HomeSearchContainer,
  HomeSection,
  SectionDescription,
  SectionHeader,
} from "./components";
// @ts-ignore
import { routePathConstants } from "../../../../../src/helper/Common/RoutePathConstants";

const Container = styled(HomeSection)` 
  flex-shrink: 0;
  gap: 36px;

  .description {
    color: #fff;
    text-align: center;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    max-width: 706px;
  }
`;

const Header = styled(SectionHeader)`
  color: #fff;
`;

const Description = styled(SectionDescription)`
  color: #fff;
`;

const SearchBarContainer = styled(HomeSearchContainer)`
  .info {
    display: none;
  }

  .search {
    height: 100%;

    input {
      border-radius: 120px !important;
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 70px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .anticon.anticon-search {
    margin-top: 0px;
    margin-right: 0px;
    background-image: url(./images/datacommons/search-icon.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 24px;
    height: 40px;
    width: 40px;
    padding: 8px 16px 8px 0;
    right: 0;
    top: 0px;

    svg {
      display: none;
    }
  }
`;

export const ExploreSection = () => {
  const history = useHistory();

  return (
    <Container className="search-wrap">
      
      <SearchBarContainer>
        <SearchBar
          initialQuery={SAMPLE_NL_QUERY}
          placeholder={SAMPLE_NL_QUERY}
          isSearching={false}
          onSearch={(q) => {
            const searchParams = new URLSearchParams();
            searchParams.set(QUERY_PARAM_QUERY, q);
            history.push(
              `${
                routePathConstants.DATA_COMMONS
              }search?${searchParams.toString()}`
            );
          }}
        />
      </SearchBarContainer>
      
    </Container>
  );
};
