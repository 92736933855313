/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { LoadingOutlined } from "@ant-design/icons";
import { Col, Layout, Spin, Switch } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";

import styled from "styled-components";

import { MenuItemType, useStoreActions, useStoreState } from "../../state";
import { COUNTRY_PLACE_TYPE, EARTH_PLACE_DCID, EARTH_PLACE_NAME, NULL_TOPIC, ROOT_TOPIC, WEB_API_ENDPOINT } from "../../utils/constants";

import { ChartConfigCategory, ChartConfigMetadata, ChartConfigTile, FulfillResponse, RelatedTopic, StatVarSpec, VarToTopicMapping } from "../../utils/types";

import {
  ChartFootnote,
  ContentCard,
  ExploreBtn,
  Footnotes,
  InvertableCard,
  MainLayoutContent,
  PlaceHeaderCard,
  SearchBar,
  TargetHeader,
  TopicHeader,
} from "../shared/components";
import GoalOverview from "../shared/goals/GoalOverview";

import { Target } from "easy-peasy";
import _ from "lodash";
import { useLocation } from "react-router";
import useFetchArticles, { GroupedArticles } from "../../../../hooks/useArticles";
import useFetchPoints from "../../../../hooks/usePoints";
import topicsData from "../../config/who_sidebar2.json";
import { theme } from "../../utils/theme";
import { keyBy } from "../goals/DataStories";
import GoalOverviewList from "../goals/GoalOverviewList";
import ViewIndex from "../goals/ViewIndex";
import IndicatorOverview from "../shared/goals/IndicatorOverview";

// Approximate chart heights for lazy-loading
const CHART_HEIGHT = 389;
const HIGHLIGHT_CHART_HEIGHT = 155;
const VARIABLE_NAME_REGEX = "(?<=\\[)(.*?)(?=\\])";
const DEFAULT_VARIABLE_NAME = "Total";
const PLACE_NAME_PROP = "unDataLabel";
const NO_MAP_TOOL_PLACE_TYPES = new Set(["UNGeoRegion", "GeoRegion"]);

export interface TileWithFootnote {
  tile: ChartConfigTile;
  footnote?: string;
}
// Interfaces to define Goal -> Target -> Indicator -> Tiles[] mapping
interface Indicators {
  [key: string]: TileWithFootnote[];
}
interface Targets {
  [key: string]: Indicators;
}

interface Goals {
  [key: string]: Targets;
}

export interface TileHierarchy {
  // map goal -> target -> indicator -> tiles, used in country/goal pages
  hierarchy: Goals;
  // list of tiles in order received from fulfillment
  orderedTiles: TileWithFootnote[];
  // Optional: string to show after " * " separator in place header
  topicNameStr?: string;
}

const SearchCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h5 {
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
    margin: 0;
  }

  margin: 0 0 1rem;
  padding: 1rem 24px;
  background: white;
  box-shadow: 0px 0px 6px rgba(3, 7, 18, 0.03), 0px 1px 22px rgba(3, 7, 18, 0.06);
`;

const PlaceTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.125rem;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0rem 24px;
  margin: 0.75rem 0 1rem 0;
  flex-wrap: no-wrap;
`;

export const ChartContentBody = styled.div`
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
  }
  .chart-content {
    min-height: 450px;
  }

  datacommons-map::part(source-separator) {
    display: none;
  }
  datacommons-map::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-line::part(source-separator) {
    display: none;
  }
  datacommons-line::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-bar::part(source-separator) {
    display: none;
  }
  datacommons-bar::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-highlight::part(source-separator) {
    display: none;
  }
  datacommons-highlight::part(source-show-metadata-link) {
    display: none;
  }
`;

export const GoalChartContentBody = styled(ChartContentBody)`
  datacommons-map::part(container),
  datacommons-highlight::part(container),
  datacommons-bar::part(container),
  datacommons-line::part(container) {
    margin-top: 0 !important;
  }
`;

const DatacommonsMapContainer = styled.div`
  datacommons-slider::part(container) {
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #e3e3e3;
    border-radius: 0;
  }
`;

const LayoutContent = styled(Layout.Content)`
  padding: 0 1rem;
  margin-bottom: 1rem;
  @media (min-width: 992px) {
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const GridSwitcher = styled.label`
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  cursor: pointer;
  button {
    margin-right: 6px;
  }
  .ant-switch-checked {
    background-color: #323232;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px);
  }
  .ant-switch-small {
    height: 14px;
    line-height: 14px;
    .ant-switch-handle {
      width: 10px;
      height: 10px;
    }
  }
  @media (min-width: 992px) {
    margin: 0rem 2rem 0px 0rem;
  }
`;
/**
 * Given a sdg topic DCID, determine the goal, target, and indicator via regex.
 * If a level of granularity is missing, the string "none" is used in its place.
 * For example:
 *   dc/topic/sdg/2.1.3 would return ["2", "1", "3"]
 *   dc/topic/sdg/4 would return ["4", "none", "none"]
 * @param topicDcid sdg topic's DCID
 * @returns the id of the topic's goal, target, and indicator, in that order
 */
export function getGoalTargetIndicator(topicDcid: string): [string, string, string] {
  // Find which goal, target, and indicator a topic belongs to
  const indicatorMatches = topicDcid.match(/dc\/topic\/sdg_(\d\d?\.\w\w?\.\w\w?)/);
  const targetMatches = topicDcid.match(/dc\/topic\/sdg_(\d\d?\.\w\w?)/);
  const goalMatches = topicDcid.match(/dc\/topic\/sdg_(\d\d?)/);
  const indicator = indicatorMatches && indicatorMatches.length > 1 ? indicatorMatches[1] : "none";
  const target = targetMatches && targetMatches.length > 1 ? targetMatches[1] : "none";
  const goal = goalMatches && goalMatches.length > 1 ? goalMatches[1] : "none";
  return [goal, target, indicator];
}

/**
 * Given an sdg topic, determine if it falls under a list of topics.
 * If an sdg topic is a subset of any member of the list, returns true.
 * For example,
 *   if topicDcid = dc/topic/sdg_1.1.1
 *   and selectedTopics = [dc/topic/sdg_1.1, dc/topic/sdg_2],
 *   then the function returns true, because 1.1.1 is a subset of 1.1
 * Used to determine if a given sdg topic matches the topic(s) selected by
 * the user or passed into search.
 * @param topicDcid sdg topic to test membership for
 * @param selectedTopics list of topics to match
 * @returns true if given topic is a subset of any member of the list,
 *          false otherwise.
 */
function isInSelectedTopics(topicDcid: string, selectedTopics: string[]): boolean {
  const [goal, target, indicator] = getGoalTargetIndicator(topicDcid);
  for (const selectedTopic of selectedTopics) {
    if (selectedTopic === ROOT_TOPIC) {
      return true;
    }
    const [selectedGoal, selectedTarget, selectedIndicator] = getGoalTargetIndicator(selectedTopic);
    if (
      indicator === selectedIndicator ||
      (selectedIndicator === "none" && target === selectedTarget) ||
      (selectedTarget === "none" && goal === selectedGoal)
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Adds tile to a given goal->target->indicator->tiles mapping
 * @param tile tile to add
 * @param hierarchy tree of goal->target->indicator->tiles to add to
 * @param topicDcid topic associated with the tile being added
 *        if provided, will only add tile if it falls under the topic
 * @param selectedTopics list of topics the current page is about
 */
function addTileToHierarchy(tile: TileWithFootnote, hierarchy: Goals, topicDcid: string, selectedTopics: string[]): void {
  if (isInSelectedTopics(topicDcid, selectedTopics)) {
    // put tile in appropriate spot in hierarchy
    const [goal, target, indicator] = getGoalTargetIndicator(topicDcid);
    if (goal in hierarchy) {
      if (target in hierarchy[goal]) {
        if (indicator in hierarchy[goal][target]) {
          hierarchy[goal][target][indicator].push(tile);
        } else {
          hierarchy[goal][target][indicator] = [tile];
        }
      } else {
        hierarchy[goal][target] = {};
        hierarchy[goal][target][indicator] = [tile];
      }
    } else {
      hierarchy[goal] = {};
      hierarchy[goal][target] = {};
      hierarchy[goal][target][indicator] = [tile];
    }
  }
}

/**
 * builds object to store tiles in order of display
 */
export function buildTileHierarchy(chartConfigCategory: ChartConfigCategory, selectedTopics: string[], varToTopics: VarToTopicMapping): TileHierarchy {
  // stores hierarchy of Goals -> Target -> Indicator -> Tiles
  const hierarchy: Goals = {};
  // stores tiles in order returned by fulfillment api
  const orderedTiles: TileWithFootnote[] = [];
  // stores topic dcids covered by the tiles
  const topicDcids: string[] = [];
  // iterate over tiles nested in chartConfigCategory
  chartConfigCategory.blocks.forEach((block) => {
    const footnote = block.footnote;
    block.columns.forEach((column) => {
      column.tiles.forEach((tile) => {
        if (tile.type === "PLACE_OVERVIEW") {
          return;
        }
        if (_.isEmpty(tile.statVarKey)) {
          return;
        }
        const statVarKey = tile.statVarKey[0];
        if (_.isEmpty(chartConfigCategory.statVarSpec[statVarKey])) {
          return;
        }
        const statVar = chartConfigCategory.statVarSpec[statVarKey].statVar;

        if (typeof varToTopics != "undefined") {
          if (_.isEmpty(varToTopics[statVar])) {
            return;
          }
        }

        const tileWithFootnote: TileWithFootnote = { tile, footnote };
        if (varToTopics) {
          for (const topic of varToTopics[statVar]) {
            addTileToHierarchy(tileWithFootnote, hierarchy, topic.dcid, selectedTopics);
          }
        }
        orderedTiles.push(tileWithFootnote);
        if (varToTopics) {
          varToTopics[statVar].forEach((topic) => topicDcids.push(topic.dcid));
        }
      });
    });
  });

  return { hierarchy, orderedTiles };
}

/**
 * Builds topic name(s) to display on search results header
 */
function buildTopicNames(mainTopics?: RelatedTopic[]): string {
  if (!mainTopics || _.isEmpty(mainTopics)) {
    return "";
  }
  // Don't show default topic nome if no topic was found
  if (mainTopics.find((topic) => topic.dcid === NULL_TOPIC)) {
    return "";
  }
  // Two topics denote a correlation/comparison
  if (mainTopics.length === 2) {
    return `${mainTopics[0].name} vs. ${mainTopics[1].name}`;
  }
  return mainTopics[0].name;
}

export const Spinner: React.FC<{ fontSize?: string }> = ({ fontSize }) => {
  const DEFAULT_SPINNER_FONT_SIZE = "1.5rem";
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: fontSize || DEFAULT_SPINNER_FONT_SIZE }} spin />} />
    </div>
  );
};

const findIndicatorByCode = (code, data) => {
  for (let i = 0; i < data.length; i++) {
    const indicator = data[i];
    if (indicator.indicator_codes && indicator.indicator_codes.includes(code)) {
      return indicator;
    }
    if (indicator.children && indicator.children.length > 0) {
      const childIndicator = findIndicatorByCode(code, indicator.children);
      if (childIndicator) {
        return childIndicator;
      }
    }
  }
  return null; // Indicator not found
};

const CountriesContent: React.FC<{
  errorMessage?: string;
  fulfillResponse?: FulfillResponse;
  hidePlaceSearch?: boolean;
  hideBreadcrumb?: boolean;
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  isFetchingFulfillment?: boolean;
  onSearch?: (query: string) => void;
  placeDcids: string[];
  query?: string;
  setPlaceDcid: (placeDcid: string) => void;
  showNLSearch?: boolean;
  userMessage?: string;
  variableDcids: string[];
}> = ({
  errorMessage,
  fulfillResponse,
  hidePlaceSearch,
  hideBreadcrumb,
  hideGoalOverview,
  hideTargetHeader,
  isFetchingFulfillment,
  onSearch,
  placeDcids,
  query,
  setPlaceDcid,
  showNLSearch,
  userMessage,
  variableDcids,
}) => {
  const rootTopics = useStoreState((s) => s.rootTopics);
  const fulfillmentsById = useStoreState((s) => s.fulfillments.byId);
  const fetchTopicFulfillment = useStoreActions((a) => a.fetchTopicFulfillment);
  const [localIsFetchingFulfillment, setLocalIsFetchingFulfillment] = useState(false);
  const [localFulfillResponse, setLocalFulfillResponse] = useState<FulfillResponse>();
  const [showIndex, setShowIndex] = useState<boolean>(false);
  const placeNamesAndDcids = useStoreState((s) => {
    const names: { name: string; dcid: string }[] = [];
    placeDcids.forEach((placeDcid) => {
      if (placeDcids && placeDcid in s.countries.byDcid) {
        names.push({
          name: s.countries.byDcid[placeDcid].name,
          dcid: placeDcid,
        });
      }
      if (placeDcid && placeDcid in s.regions.byDcid) {
        names.push({ name: s.regions.byDcid[placeDcid].name, dcid: placeDcid });
      }
      if (placeDcid === EARTH_PLACE_DCID) {
        names.push({ name: EARTH_PLACE_NAME, dcid: placeDcid });
      }
    });

    return names;
  });
  // Determine if we're in the search pages.
  // Used to hide PageHeaderCard if we're showing search results
  const location = useLocation();
  const isSearch = location.pathname.includes("/search");
  const isGoal = location.pathname.includes("/goals");

  /**
   * Fetch page content
   */
  useEffect(() => {
    document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
    // If a fulfill response was passed in, use that
    if (isSearch) {
      setLocalFulfillResponse(fulfillResponse);
      return;
    }
    // Otherwise fetch a fulfill response based on the specified variables & place
    if (!variableDcids || variableDcids.length === 0 || placeDcids.length === 0) {
      return;
    }
    (async () => {
      setLocalIsFetchingFulfillment(true);
      const topicDcids = variableDcids.map((dcid) => {
        if (dcid.indexOf("/g/") !== -1) {
          return dcid.replace("/g/", "/topic/").toLocaleLowerCase();
        }
        return dcid;
      });

      const fulfillment = await fetchTopicFulfillment({
        entityDcids: placeDcids,
        variableDcids: topicDcids,
        fulfillmentsById,
      });
      setLocalIsFetchingFulfillment(false);
      setLocalFulfillResponse(fulfillment);
    })();
  }, [fulfillResponse, isSearch, placeDcids, variableDcids]);

  /** Show loading state if we are passing in a fulfillment response from outside this component */
  useEffect(() => {
    if (isFetchingFulfillment === undefined) {
      return;
    }
    if (localIsFetchingFulfillment !== isFetchingFulfillment) {
      setLocalIsFetchingFulfillment(isFetchingFulfillment);
    }
  }, [isFetchingFulfillment]);

  const topicNames = buildTopicNames(localFulfillResponse?.relatedThings?.mainTopics);
  const [sdgNumber] = topicNames?.split(":");
  const [goalNumber] = sdgNumber?.split(".");
  const [goal] = rootTopics.filter((t) => t.topicDcid === `${ROOT_TOPIC}_${goalNumber}`);

  if (isGoal && variableDcids.length > 0 && variableDcids[0] === ROOT_TOPIC && placeDcids.length > 0 && placeDcids[0] === EARTH_PLACE_DCID) {
    return (
      <Layout style={{ height: "100%", flexGrow: 1 }}>
        <Layout.Content style={{ padding: "12px 0", background: theme.searchBackgroundColor }}>
          <PlaceTitle style={{ marginBottom: "1rem", display: "block" }}>
            <div>
              {placeNamesAndDcids.length > 0 ? (
                ""
              ) : // "All Sustainable Development Goals"
              // placeNamesAndDcids.map((p) => p.name).join(", ")
              placeDcids.length > 0 ? (
                <Spinner />
              ) : (
                "Select a country"
              )}
            </div>
          </PlaceTitle>
          {/* <AllGoalsOverview /> */}
          <GoalOverviewList rootTopics={rootTopics} />
          {/* {rootTopics.map((_, topicIndex) => (
            <MainLayoutContent key={topicIndex}>
              <GoalOverview goalNumber={topicIndex + 1} showExploreLink={true} />
            </MainLayoutContent>
          ))} */}
        </Layout.Content>
      </Layout>
    );
  }

  const params = Object.fromEntries(new URLSearchParams(location.search));
  const sdg = params.v?.split("_")[1];
  const lvl = sdg?.split(".").length;
  const changeIndexView = (v: boolean) => setShowIndex(v);
  const blockID = location.hash?.replace("#", "");
  const isBlock = location.hash && blockID > -1;
  return (
    <Layout id="top" style={{ height: "100%", flexGrow: 1 }}>
      <Layout.Content style={{ padding: "0rem 0", background: theme.searchBackgroundColor }}>
        {showNLSearch && (
          <SearchCard>
            <SearchBar
              initialQuery={query}
              isSearching={localIsFetchingFulfillment}
              onSearch={(query) => {
                if (onSearch) {
                  onSearch(query);
                }
              }}
            />
          </SearchCard>
        )}

        {errorMessage && <ErorrMessage message={errorMessage} />}

        {!isGoal && (placeNamesAndDcids.length > 0 || userMessage) && (
          <LayoutContent>
            {!hideBreadcrumb && (
              <PlaceHeaderCard
                placeNamesAndDcids={placeNamesAndDcids}
                hideBreadcrumbs={isSearch}
                hidePlaceSearch={hidePlaceSearch}
                isSearch={isSearch}
                topicNames={topicNames}
                setSelectedPlaceDcid={setPlaceDcid}
                userMessage={userMessage}
                variableDcids={variableDcids}
              />
            )}
          </LayoutContent>
        )}

        {isGoal && (
          <PlaceTitle style={{ marginBottom: "1rem" }}>
            <div>
              {/* {lvl === 2 && `${goalNumber}: ${goal?.name} / Explore Target ${sdgNumber}`} */}
              {/* {lvl > 2 && `${goalNumber}: ${goal?.name} / Explore Indicator ${sdgNumber}`} */}
            </div>
            {lvl > 2 && !isBlock && (
              <GridSwitcher>
                <Switch checked={showIndex} onChange={changeIndexView} size="small" /> Data catalogue
              </GridSwitcher>
            )}
          </PlaceTitle>
        )}

        <MainLayoutContent>
          {!isSearch && variableDcids.length === 0 && !fulfillResponse && placeDcids.length === 0 && (
            <ContentCard>
              <h5>Explore SDG progress</h5>
              <p>Select a country to get started.</p>
            </ContentCard>
          )}
          {localIsFetchingFulfillment ? (
            <ContentCard>
              <Spinner />
            </ContentCard>
          ) : !isBlock && showIndex && lvl > 2 ? (
            <ViewIndex fulfillResponse={localFulfillResponse as FulfillResponse} buildTileHierarchy={buildTileHierarchy} />
          ) : (
            <StyledChartContent
              fulfillResponse={localFulfillResponse}
              blockID={blockID}
              placeDcids={placeDcids}
              selectedVariableDcids={variableDcids}
              isSearch={isSearch}
              hideGoalOverview={hideGoalOverview}
              hideTargetHeader={hideTargetHeader}
            />
          )}
          <Footnotes />
        </MainLayoutContent>
      </Layout.Content>
    </Layout>
  );
};

const ChartContent: React.FC<{
  fulfillResponse?: FulfillResponse;
  placeDcids: string[];
  selectedVariableDcids: string[];
  isSearch: boolean;
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  blockID?: number | undefined;
}> = (props) => {
  const { fulfillResponse, placeDcids, isSearch, hideGoalOverview, hideTargetHeader, blockID } = props;

  const location = useLocation();
  const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);

  if (!fulfillResponse || fulfillResponse.failure) {
    return null;
  }
  // Return no data error if there is nothing to show.
  if (!isSearch && Object.keys(fulfillResponse?.relatedThings?.varToTopics || {}).length === 0) {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    if (!params.v)
      return (
        <ContentCard>
          <ErorrMessageText>No data found.</ErorrMessageText>
        </ContentCard>
      );

    const [goal, target, indicator] = getGoalTargetIndicator(params.v);
    const goalNumber = target.split(".")[0];
    const rootTopic = params.v == `${ROOT_TOPIC}_${target}` && sidebarMenuHierarchy.find((i: MenuItemType) => i.key == `${ROOT_TOPIC}_${goalNumber}`);
    const color = theme.sdgColors[Number(goalNumber) - 1];
    const topic = rootTopic && rootTopic?.children.find((i: MenuItemType) => i.key == `${ROOT_TOPIC}_${target}`);
    return (
      <>
        <InvertableCard className="-dc-goal-overview" color={color} invert={indicator === "none"}>
          {indicator === "none" ? (
            <TargetHeader color={color} target={target} />
          ) : (
            <>
              <TopicHeader color={color} target={indicator} />
            </>
          )}
        </InvertableCard>
        {indicator !== "none" && <IndicatorOverview showNotFound={true} limit={1} color={color} dcid={`${ROOT_TOPIC}_${indicator}`} />}

        {topic &&
          topic.children
            ?.filter((a) => !a.key.includes("summary-"))
            .map((item) => (
              <div>
                <ContentCard>
                  <TopicHeader color={color} target={item.key.split("_")[1]} />
                </ContentCard>
                <IndicatorOverview limit={1} color={color} dcid={item.key} />
              </div>
            ))}
      </>
    );
  }

  return (
    <>
      {fulfillResponse.config.categories &&
        fulfillResponse.config.categories.map((chartConfigCategory, i) => (
          <ChartCategoryContent
            key={i}
            placeDcids={placeDcids}
            chartConfigCategory={blockID ? { ...chartConfigCategory, blocks: [chartConfigCategory.blocks[blockID]] } : chartConfigCategory}
            blockTitle={blockID && chartConfigCategory.blocks[blockID].title}
            fulfillResponse={fulfillResponse}
            isSearch={isSearch}
            hideGoalOverview={hideGoalOverview}
            hideTargetHeader={hideTargetHeader && !blockID}
          />
        ))}
    </>
  );
};

const ChartCategoryContent: React.FC<{
  chartConfigCategory: ChartConfigCategory;
  fulfillResponse: FulfillResponse;
  isSearch: boolean;
  placeDcids: string[];
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  blockTitle?: string | boolean;
}> = ({ chartConfigCategory, fulfillResponse, isSearch, placeDcids, hideGoalOverview, hideTargetHeader, blockTitle }) => {
  const mainTopicDcids = fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
  const processedTiles = buildTileHierarchy(chartConfigCategory, mainTopicDcids, fulfillResponse.relatedThings.varToTopics);

  if (isSearch) {
    // Show all tiles in one card without headers
    return (
      <ContentCard className="-dc-goal-overview" style={{ paddingTop: "0" }}>
        <ChartContentBody>
          {processedTiles.orderedTiles.map((tile, i) => (
            <ChartTile
              fulfillResponse={fulfillResponse}
              key={`search-result-tile-${i}`}
              placeDcids={placeDcids}
              tileWithFootnote={tile}
              statVarSpec={chartConfigCategory.statVarSpec}
            />
          ))}
        </ChartContentBody>
      </ContentCard>
    );
  }
  return (
    <>
      {Object.keys(processedTiles.hierarchy)
        .sort()
        .map((goal, i) => {
          return (
            <ChartGoalBlock
              fulfillResponse={fulfillResponse}
              goal={goal}
              key={i}
              placeDcids={placeDcids}
              statVarSpec={chartConfigCategory.statVarSpec}
              targetData={processedTiles.hierarchy[goal]}
              hideGoalOverview={hideGoalOverview}
              hideTargetHeader={hideTargetHeader}
              blockTitle={blockTitle}
            />
          );
        })}
    </>
  );
};

// Displays all cards associated with a goal, along with goal's overview tile
const ChartGoalBlock: React.FC<{
  fulfillResponse: FulfillResponse;
  chartConfigMetadata?: ChartConfigMetadata;
  placeDcids: string[];
  goal: string;
  targetData: Targets;
  statVarSpec: StatVarSpec;
  hideGoalOverview?: boolean;
  hideTargetHeader?: boolean;
  target?: Target;
  blockTitle?: string | boolean;
}> = ({ fulfillResponse, placeDcids, goal, targetData, statVarSpec, hideGoalOverview, hideTargetHeader, blockTitle }) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const sdg = params.v && params.v.split("_")[1];
  // const target = (location.search.split('_')[1]+'') as string;
  const lvl = sdg?.split(".").length;
  const hideOverview = sdg && lvl < 2;

  const { fetchArticles } = useFetchArticles();
  const [topics, setTopics] = useState<GroupedArticles | null>(null);
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    fetchArticles([`${ROOT_TOPIC}_${sdg}`]).then((resp) => {
      if (isMounted.current) setTopics(resp);
    });
    return () => {
      isMounted.current = false;
    };
  }, []);
  const childTopics = keyBy(
    fulfillResponse?.relatedThings?.childTopics.map((a) => ({ ...a, number: a.dcid.split("_")[1] })),
    "number"
  );

  return (
    <>
      {placeDcids[0] === EARTH_PLACE_DCID && (
        <div>
          {!hideGoalOverview && !!hideOverview && (
            <>
              {topics && topics[`${ROOT_TOPIC}_${sdg}`] ? (
                <GoalOverview goalData={topics[`${ROOT_TOPIC}_${sdg}`]} showDataStories={true} goalNumber={Number(goal)} showExploreLink={true} />
              ) : (
                <ContentCard>
                  <Spinner />
                </ContentCard>
              )}
            </>
          )}
        </div>
      )}
      {Object.keys(lvl < 2 ? childTopics : targetData)
        .sort((a, b) => {
          const [numA, suffixA] = a.split(".");
          const [numB, suffixB] = b.split(".");
          const numComparison = parseInt(numA) - parseInt(numB);
          if (numComparison !== 0) {
            return numComparison;
          }
          // Handle the case where suffix is numeric
          if (!isNaN(suffixA) && !isNaN(suffixB)) {
            return parseInt(suffixA) - parseInt(suffixB);
          }
          // Handle the case where suffix is alphabetic
          if (isNaN(suffixA) && isNaN(suffixB)) {
            return suffixA.localeCompare(suffixB);
          }
          // Handle mixed case: numeric suffixes should come before alphabetic suffixes
          return isNaN(suffixA) ? 1 : -1;
        })
        .map((target, i) => {
          return (
            targetData[target] && (
              <ChartTargetBlock
                key={`${goal}-${i}`}
                fulfillResponse={fulfillResponse}
                placeDcids={placeDcids}
                target={target}
                indicatorData={targetData[target]}
                statVarSpec={statVarSpec}
                hideTargetHeader={hideTargetHeader}
                hideChartBody={!!sdg && !!targetData[sdg]}
                blockTitle={blockTitle}
              />
            )
            //   :
            //   (lvl < 2 && <PortableTargetBlock
            //     key={`${goal}-${i}`}
            //     target={target}
            //     statVarSpec={statVarSpec}
            //     hideTargetHeader={hideTargetHeader}
            //     hideChartBody={!!sdg && !!targetData[sdg]}
            //     blockTitle={blockTitle}
            //     topic={childTopics[target]}
            //   />
            // )
          );
        })}
      {sdg &&
        !!targetData[sdg] &&
        Object.keys(targetData[sdg])
          .sort()
          .map((target, i) => {
            return (
              <ChartTargetBlock
                key={`${goal}-${i}`}
                fulfillResponse={fulfillResponse}
                placeDcids={placeDcids}
                target={target}
                indicatorData={{ [target]: targetData[sdg][target] }}
                statVarSpec={statVarSpec}
                hideTargetHeader={hideTargetHeader}
              />
            );
          })}
    </>
  );
};

// Displays the card associated with a target, along with target's header
const ChartTargetBlock: React.FC<{
  fulfillResponse: FulfillResponse;
  indicatorData: Indicators;
  placeDcids: string[];
  statVarSpec: StatVarSpec;
  target: string;
  hideTargetHeader?: boolean;
  hideChartBody?: boolean;
  blockTitle?: string | boolean;
}> = ({ fulfillResponse, indicatorData, placeDcids, statVarSpec, target, hideTargetHeader, hideChartBody, blockTitle }) => {
  const goalNumber = Number(target.split(".")[0]) || 1;
  const color = theme.sdgColors[goalNumber - 1];

  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const sdg = params.v?.split("_")[1] || target;
  const lvl = sdg?.split(".").length;
  const targetLvl = target.split(".").length;
  const isTarget = targetLvl > lvl;
  const isGoal = location.pathname.includes("/goals");

  return (
    <>
      <InvertableCard className="-dc-goal-overview" color={color} invert={params.v?.split("_")[1] && lvl === 2 && targetLvl === 2}>
        <div>
          {!hideTargetHeader && lvl < 3 && targetLvl < 3 && (
            <div>
              <TargetHeader color={color} target={target} />
              {/* {!hideChartBody && <Divider color={color} />} */}
            </div>
          )}
          {(lvl > 2 || targetLvl > 2) && <TopicHeader color={color} target={isTarget ? target : sdg} />}
        </div>
      </InvertableCard>
      {isGoal && lvl > 2 && !location.hash && <IndicatorOverview color={color} dcid={params.v} />}
      {isGoal && lvl === 2 && targetLvl > 2 && !location.hash && <IndicatorOverview limit={1} color={color} dcid={`${ROOT_TOPIC}_${target}`} />}
      {isGoal && lvl > 2 && location.hash && (
        <ContentCard>
          <Col className="ant-col" style={{ flex: "1 1 0%;" }}>
            <span>{blockTitle}</span>
          </Col>
          {/* <div class="ant-col" style="flex: 0 1 0%;">
      <img src="./images/datacommons/ta-disasters.svg" alt="">
    </div> */}
        </ContentCard>
      )}

      {!hideChartBody &&
        (lvl < 3 ? [Object.keys(indicatorData)[0]] : Object.keys(indicatorData)).sort().map((indicator, i) => {
          return (
            <ChartIndicatorBlock
              fulfillResponse={fulfillResponse}
              indicator={indicator}
              key={`${target}=${i}`}
              placeDcids={placeDcids}
              statVarSpec={statVarSpec}
              tiles={indicatorData[indicator]}
              hideTargetHeader={hideTargetHeader}
              underTarget={target}
            />
          );
        })}
    </>
  );
};

// Displays the tiles associated with a single indicator
const ChartIndicatorBlock: React.FC<{
  fulfillResponse: FulfillResponse;
  indicator: string;
  placeDcids: string[];
  statVarSpec: StatVarSpec;
  tiles: TileWithFootnote[];
  hideTargetHeader?: boolean;
  underTarget?: string;
}> = ({ fulfillResponse, indicator, placeDcids, statVarSpec, tiles, hideTargetHeader, underTarget }) => {
  const goalNumber = Number(indicator.split(".")[0]) || 1;
  const color = theme.sdgColors[goalNumber - 1];
  const location = useLocation();
  const { fetchPoints } = useFetchPoints();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const sdg = params.v?.split("_")[1] || indicator;
  const lvl = sdg?.split(".").length;
  // const lvl = underTarget?.split('.').length
  const [itemsToShow, setItemsToShow] = useState(10);
  const [emptyTiles, setEmptyTiles] = useState<number[]>([]);
  const items = lvl < 3 ? [tiles[0]] : tiles;

  // check highlights exist
  useEffect(() => {
    if (items?.length) {
      let highlights = items.map((item, i) => ({ ...item, index: i, statVar: item.tile.statVarKey[0] })).filter((i) => i.tile.type === "HIGHLIGHT");
      let [entities] = placeDcids;
      let variables = highlights.map((item) => item.tile.statVarKey[0]);
      fetchPoints({
        entities,
        variables,
      }).then((resp) => {
        setEmptyTiles(highlights.filter((h) => !resp?.data[h.statVar][entities].value).map((t) => t.index));
      });
    }
  }, [fetchPoints]);

  return (
    <GoalChartContentBody>
      {/* {placeDcids[0] === EARTH_PLACE_DCID && (
        <div>
          {!hideTargetHeader && (
            <HeadlineTile backgroundColor={color} indicator={indicator} />
          )}
        </div>
      )} */}
      {items?.length &&
        items.slice(0, itemsToShow).map((tile, i) => (
          <ContentCard key={i} hidden={emptyTiles.includes(i)}>
            <ChartTile fulfillResponse={fulfillResponse} key={`${indicator}-${i}`} placeDcids={placeDcids} tileWithFootnote={tile} statVarSpec={statVarSpec} />
          </ContentCard>
        ))}
      {itemsToShow < items.length && (
        // Show "Load More" if there are more items to load
        <ExploreBtn>
          <button onClick={() => setItemsToShow(itemsToShow + 10)}>Explore more</button>
        </ExploreBtn>
      )}
    </GoalChartContentBody>
  );
};

export const ChartTile: React.FC<{
  fulfillResponse: FulfillResponse;
  statVarSpec: StatVarSpec;
  placeDcids: string[];
  tileWithFootnote: TileWithFootnote;
}> = ({ fulfillResponse, placeDcids, tileWithFootnote, statVarSpec }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);

  useEffect(() => {
    if (isIntersecting && !loaded) {
      setLoaded(true);
    }
  }, [isIntersecting]);

  useEffect(() => {
    // @ts-ignore
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  // useEffect(() => {
  //   console.log(isIntersecting, tileWithFootnote.tile, 'tile')
  // }, [tileWithFootnote.tile]);

  if (placeDcids.length === 0) {
    return <div ref={ref} />;
  }

  const tile = tileWithFootnote.tile;
  const footnote = tileWithFootnote.footnote;
  const placeDcid = placeDcids[0];
  const placeType = fulfillResponse.place.place_type;
  const containedPlaceTypes = fulfillResponse.config.metadata?.containedPlaceTypes || {};
  const childPlaceType = placeType in containedPlaceTypes ? containedPlaceTypes[placeType] : COUNTRY_PLACE_TYPE;

  const tileStatVars = tile.statVarKey.map((statVarKey) => statVarSpec[statVarKey]?.statVar);
  const foundIndicator = findIndicatorByCode(tileStatVars[0], topicsData);

  let component = null;
  const height = tile.type === "HIGHLIGHT" ? HIGHLIGHT_CHART_HEIGHT : CHART_HEIGHT;
  if (tile.type === "PLACE_OVERVIEW") {
    component = <></>;
  } else if (tile.type === "BAR") {
    component = (
      <>
        {/** @ts-ignore */}
        <datacommons-bar
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          header={tile.title}
          variables={tileStatVars.join(" ")}
          places={placeDcids.join(" ")}
          showExploreMore={false}
          variableNameRegex={VARIABLE_NAME_REGEX}
          defaultVariableName={DEFAULT_VARIABLE_NAME}
          placeNameProp={PLACE_NAME_PROP}
        >
          <div slot="footer">
            <ChartFootnote text={footnote ? footnote : ""} />
          </div>
          {/** @ts-ignore */}
        </datacommons-bar>
      </>
    );
  } else if (tile.type === "HIGHLIGHT") {
    component = (
      <>
        {/** @ts-ignore */}
        <datacommons-highlight
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          header={tile.title}
          variable={tileStatVars.join(" ")}
          place={placeDcid}
        />
      </>
    );
  } else if (tile.type === "LINE") {
    component = (
      <>
        {/** @ts-ignore */}
        <datacommons-line
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          header={tile.title}
          variables={tileStatVars.join(" ")}
          places={tile.placeDcidOverride || placeDcids.join(" ")}
          variableNameRegex={VARIABLE_NAME_REGEX}
          showExploreMore={false}
          defaultVariableName={DEFAULT_VARIABLE_NAME}
          timeScale="year"
          placeNameProp={PLACE_NAME_PROP}
          layout={{
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20,
            },
          }}
        >
          <div slot="footer">
            <ChartFootnote text={footnote} />
          </div>
          {/** @ts-ignore */}
        </datacommons-line>
      </>
    );
  } else if (tile.type === "MAP") {
    const channel = `map-${tileStatVars.join("__")}`;
    // To prevent showing sub-national map data, only show maps if
    // the child place is Country (for World or regional views)
    const showMap = childPlaceType === COUNTRY_PLACE_TYPE;
    component = showMap ? (
      <DatacommonsMapContainer>
        {/** @ts-ignore */}
        <datacommons-map
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          subscribe={channel}
          header={`${tile.title}*`}
          variable={tileStatVars.join(" ")}
          parentPlace={placeDcid}
          childPlaceType={childPlaceType}
          showExploreMore={false}
          placeNameProp={PLACE_NAME_PROP}
          geoJsonProp={"geoJsonCoordinatesUN"}
          date="HIGHEST_COVERAGE"
        >
          <div slot="footer">
            {/** @ts-ignore */}
            <datacommons-slider
              apiRoot={WEB_API_ENDPOINT}
              publish={channel}
              variable={tileStatVars.join(" ")}
              parentPlace={placeDcid}
              childPlaceType={childPlaceType}
            />
            <ChartFootnote text={"This chart incorporates various dates to create the most relevant visual. " + (footnote ? footnote : "")} />
          </div>
          {/** @ts-ignore */}
        </datacommons-map>
      </DatacommonsMapContainer>
    ) : (
      <></>
    );
  } else if (tile.type === "GAUGE") {
    component = (
      <>
        {/** @ts-ignore */}
        <datacommons-gauge
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          header={tile.title}
          variable={tileStatVars.join(" ")}
          place={placeDcid}
          min="0"
          max="100"
        >
          <div slot="footer">
            <ChartFootnote text={footnote} />
          </div>
          {/** @ts-ignore */}
        </datacommons-gauge>
      </>
    );
  } else if (tile.type === "SCATTER") {
    component = (
      <>
        {/** @ts-ignore */}
        <datacommons-scatter
          apiRoot={WEB_API_ENDPOINT}
          sources={foundIndicator ? foundIndicator.indicator_source : undefined}
          header={tile.title}
          variables={tileStatVars.join(" ")}
          parentPlace={placeDcid}
          childPlaceType={childPlaceType}
          placeNameProp={PLACE_NAME_PROP}
          showExploreMore={false}
        >
          <div slot="footer">
            <ChartFootnote text={footnote} />
          </div>
          {/** @ts-ignore */}
        </datacommons-scatter>
      </>
    );
  } else if (tile.type === "RANKING") {
    // Do not render ranking tiles
    component = <></>;
  } else {
    component = (
      <div>
        Unknown chart type {tile.type} for chart {'"'}
        {tile.title}
        {'"'}
      </div>
    );
  }

  return (
    <div className={`-dc-chart-tile -dc-chart-tile-${tile.type}`} ref={ref} style={{ minHeight: !loaded ? height : undefined }}>
      {loaded && component}
    </div>
  );
};

export const ErorrMessageText = styled.div<{ error?: boolean }>`
  font-size: 18px;
`;
const ErorrMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <MainLayoutContent>
      <ContentCard>
        <ErorrMessageText>{message}</ErorrMessageText>
      </ContentCard>
    </MainLayoutContent>
  );
};
export default CountriesContent;

const StyledChartContent = styled(ChartContent)`
  padding: 16px 16px 16px 16px;
`;
