import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import useFetchArticles, { GroupedArticles } from "../../../../hooks/useArticles";
import { useStoreState } from "../../state";
import { ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { buildTileHierarchy, ChartTile, GoalChartContentBody, Spinner } from "../countries/CountriesContent";
import { ContentCard, InvertableCard, TargetHeader } from "../shared/components";
import GoalOverview from "../shared/goals/GoalOverview";
import { keyBy } from "./DataStories";
import { unSort } from "./GoalContent";

const ExploreGoal: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    // selectedVariableDcids: string[];
    isSearch?: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined;
    goal: string
  }> = ({ fulfillResponse, placeDcids, goal }) => {

    const { fetchArticles } = useFetchArticles();
    const [topics, setTopics] = useState<false | GroupedArticles | undefined | null>(null);
    const [childTopics, setChildTopics] = useState<false | GroupedArticles | undefined | null>({});
    const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);
    const mainTopicDcids = fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
    const processedTiles = fulfillResponse?.config && buildTileHierarchy(fulfillResponse?.config?.categories[0], mainTopicDcids, fulfillResponse.relatedThings.varToTopics);
    const isMounted = useRef(true);
    // const varToTopics = fulfillResponse?.relatedThings?.varToTopics
    
    useEffect(() => {
        isMounted.current = true;
        fetchArticles([`${ROOT_TOPIC}_${goal}`]).then(resp => {
          if (isMounted.current){
            setTopics(resp)
          }
        });
        return () => {
          isMounted.current = false;
        };
      }, [goal, fetchArticles]);

    useEffect(()=>{
      if(fulfillResponse?.relatedThings?.childTopics && sidebarMenuHierarchy[goal]){
        setChildTopics(!sidebarMenuHierarchy[goal].children ? {} : keyBy(sidebarMenuHierarchy[goal].children.filter(a=>!a.key?.includes('summary-')).map(a=>({...a, number: a.key.split('_')[1]})), 'number'))
      }
    },[fulfillResponse])

    const color = theme.sdgColors[Number(goal) - 1];
    const statVarSpec = fulfillResponse?.config.categories && fulfillResponse?.config.categories[0]
    

    return <Layout style={{ height: "100%", flexGrow: 1 }}  id="top">
            <Layout.Content style={{ padding: "24px", background: theme.searchBackgroundColor }}>
                {
                    topics && Object.keys(topics).length && topics[`${ROOT_TOPIC}_${goal}`] ?
                    <GoalOverview goalData={topics[`${ROOT_TOPIC}_${goal}`]} showDataStories={true} goalNumber={Number(goal)} showExploreLink={true} />
                    : <ContentCard><Spinner/></ContentCard>
                }
                
                {childTopics && Object.keys(childTopics)
                .sort(unSort)
                .map((target, i) => {
                  let items = processedTiles && processedTiles?.hierarchy[goal] && processedTiles?.hierarchy[goal][target]
                  let firstTile = items && Object.values(items)[0][0]
                  // console.log(firstTile, goal, target, statVarSpec, 'tileWith')
                return childTopics && childTopics[target] && items &&
                  <React.Fragment key={i}>
                    <InvertableCard className="-dc-goal-overview" color={color}>
                        <TargetHeader color={color} target={`${target}`} />
                    </InvertableCard>
                    {fulfillResponse && items && statVarSpec && firstTile &&
                    <GoalChartContentBody>
                      <ContentCard key={i}>
                        <ChartTile fulfillResponse={fulfillResponse} key={`${target}-${i}`} placeDcids={placeDcids} tileWithFootnote={firstTile} statVarSpec={statVarSpec.statVarSpec} />
                      </ContentCard>
                    </GoalChartContentBody>
                    }
                  </React.Fragment>
                })}

                
            </Layout.Content>
            </Layout>
  }
export default ExploreGoal