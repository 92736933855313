import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { GroupedArticles } from "../../../../hooks/useArticles";
import { useStoreState } from "../../state";
import { ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { buildTileHierarchy, ChartTile, GoalChartContentBody } from "../countries/CountriesContent";
import { ContentCard, InvertableCard, TargetHeader, TopicHeader } from "../shared/components";
import IndicatorOverview from "../shared/goals/IndicatorOverview";
import { keyBy } from "./DataStories";
import { unSort } from "./GoalContent";

const ExploreTarget: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    isSearch?: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined;
    goal: string;
    target: string
  }> = ({ fulfillResponse, placeDcids, goal, target }) => {

    const [childTopics, setChildTopics] = useState<false | GroupedArticles | undefined | null>({});
    const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);
    const mainTopicDcids = fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
    const processedTiles = fulfillResponse?.config && buildTileHierarchy(fulfillResponse?.config?.categories[0], mainTopicDcids, fulfillResponse.relatedThings.varToTopics);

    useEffect(()=>{
      if(fulfillResponse?.relatedThings?.childTopics && sidebarMenuHierarchy[goal]){
        let topicItem = sidebarMenuHierarchy[goal].children.find(i=>i.key===`${ROOT_TOPIC}_${target}`)
        setChildTopics(!topicItem.children ? {} : keyBy(topicItem.children.filter(a=>!a.key?.includes('summary-')).map(a=>({...a, number: a.key.split('_')[1]})), 'number'))
      }
    },[fulfillResponse])

    const color = theme.sdgColors[Number(goal) - 1];
    const statVarSpec = fulfillResponse?.config.categories && fulfillResponse?.config.categories[0]
    
    return <Layout style={{ height: "100%", flexGrow: 1 }}  id="top">
            <Layout.Content style={{ padding: "24px", background: theme.searchBackgroundColor }}>
                
                <InvertableCard className="-dc-goal-overview" color={color} invert={true}>
                    <TargetHeader color={color} target={`${target}`} />
                </InvertableCard>
                
                {childTopics && Object.keys(childTopics)
                .sort(unSort)
                .map((indicator, i) => {
                  let items = processedTiles && processedTiles?.hierarchy[goal] && processedTiles?.hierarchy[goal][target]
                  let firstTile = items && items[indicator] && items[indicator][0]
                //   console.log(firstTile, items, goal, target, indicator, statVarSpec, 'tileWith')
                return childTopics &&
                  <React.Fragment key={i}>
                    <InvertableCard className="-dc-goal-overview" color={color}>
                        <TopicHeader color={color} target={`${indicator}`} />
                    </InvertableCard>
                    <IndicatorOverview showNotFound={false} limit={1} color={color} dcid={`${ROOT_TOPIC}_${indicator}`}/>
                    {fulfillResponse && items && statVarSpec && firstTile &&
                    <GoalChartContentBody>
                      <ContentCard key={i}>
                        <ChartTile fulfillResponse={fulfillResponse} key={`${indicator}-${i}`} placeDcids={placeDcids} tileWithFootnote={firstTile} statVarSpec={statVarSpec.statVarSpec} />
                      </ContentCard>
                    </GoalChartContentBody>
                    }
                  </React.Fragment>
                })}
            </Layout.Content>
            </Layout>
  }
export default ExploreTarget