/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from "styled-components";
import AppLayoutContent from "../shared/AppLayoutContent";
import { DataInsights } from "./DataInsights";
import { ExploreSection } from "./ExploreSection";
import { MapSection } from "./MapSection";
import { News } from "./News";
import { Partners } from "./Partners";
import { SDGSection } from "./SDGSection";
import { ThematicAreas } from "./ThematicAreas";
import { HomeSection, SectionDescription } from "./components";

//import { tsParticles } from "https://cdn.jsdelivr.net/npm/@tsparticles/engine@3.1.0/+esm";
//import { loadAll } from "https://cdn.jsdelivr.net/npm/@tsparticles/all@3.1.0/+esm";
import { loadAll } from "@tsparticles/all";
import { tsParticles } from "@tsparticles/engine";

const HomeContainer = styled(HomeSection)`
  position: relative;
  background-color: #283460;

  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  div#tsparticles:after {
    position: absolute;
    z-index: 0;
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all 5s;
    background-size: cover;
    background-image: url(./images/datacommons/particles-mob.jpeg);
    @media (min-width: 767px) {
      background-image: url(./images/datacommons/particles-pc.jpeg);
    }
  }
  div#tsparticles.loaded:after {
    opacity: 0;
  }
  .page-header {
    z-index: 1;
    padding: 4rem 0;
    max-width: 836px;
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 16px;

    img {
      // width: 100%;
      height: 100px;
    }
  }
  .search input.ant-input {
    font-size: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    color: #7c7c7c;
  }
  @media (max-width: 820px) {
    .page-header {
      padding: 3rem 0 122px;
      img {
        width: 254px;
        height: 65.25px;
      }
    }
    .search input.ant-input {
      font-size: 14px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-right: 45px;
      color: #7c7c7c;
    }

    .search-wrap {
      padding: 45px 20px !important;
    }
    .explore-section {
      padding: 45px 20px !important;
    }
  }
`;

const Home = () => {
  async function loadParticles(options: {}) {
    await loadAll(tsParticles);
    await tsParticles
      .load({ id: "tsparticles", options })
      .then(() => {
        document.getElementById("tsparticles")?.classList.add("loaded");
      })
      .catch((error) => {
        console.error("Error while loading tsparticles: ", error);
      });
  }

  const configs = {
    fpsLimit: 60,
    fullScreen: {
      enable: false,
    },
    particles: {
      number: {
        value: 90,
        density: {
          enable: true,
        },
      },
      color: {
        value: "#D3D3D3",
      },
      links: {
        enable: true,
        value: "#D3D3D3",
        distance: 120,
        opacity: 0.3,
        width: 1,
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.2,
      },
      size: {
        value: {
          min: 1,
          max: 3,
        },
      },
      move: {
        enable: true,
        speed: 0.5,
      },
    },
    background: {
      //color: "#000000"
    },
    poisson: {
      enable: true,
    },
  };

  loadParticles(configs);

  return (
    <>
      <AppLayoutContent
        style={{
          background: "white",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HomeContainer style={{ padding: 0 }}>
          <div id="tsparticles">
            <canvas
              data-generated="false"
              style={{ width: "100%", height: "100%", pointerEvents: "none" }}
              aria-hidden="true"
              width="2880"
              height="952"
            ></canvas>
          </div>
          <div className="page-header">
            <img src={"./images/datacommons/icon-undata-white.svg"} />

            <ExploreSection />

            <SectionDescription>
              Introducing UN Data, a powerful tool for extracting insights from data available across the UN system.
              <br /> Search and explore high-quality datasets and digital public goods right at your fingertips to empower and expedite evidence-based
              decision-making.
            </SectionDescription>
          </div>
        </HomeContainer>
        <SDGSection />
        <MapSection />
        <ThematicAreas />
        <News />
        <DataInsights />
        <Partners />
        {/*
          <PlaceSection />
          <GoalSection />
          <ExploreSection />*/}
      </AppLayoutContent>
    </>
  );
};

export default Home;
