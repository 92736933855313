import { Drawer, Grid } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import React, { useState } from 'react';
import styled from 'styled-components';
type Props = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
    headerHeight: string
}

const ToggleButton = (props:any) => {
    return (
    <span {...props} className="ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left ">
        <span className='material-icons-outlined'>list</span>
    </span>
    )
}

const StickyButton = styled(ToggleButton)`
    top: 0px;
    position: sticky;
    border-radius: 0px 110% 110% 0px;
    padding: 6px 14px 6px 8px;
    width: 48px;
    background: #333333;
    white-space: nowrap;
    > span {
        line-height:42px;
        width:100%;
    }
`;
const SidebarDrawer = styled(Drawer)`
.ant-drawer-content-wrapper{
    border-radius: 0 10px 10px 0;
}
.ant-drawer-body {
    padding:16px 0;
}
span {
    user-select: none;
}
`;

const RespSidebar = ({children, headerHeight}: Props) => {
    const [open, setOpen] = useState(false);
    const screens = Grid.useBreakpoint();
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
            return (
            <React.Fragment>{
                screens.xs ?
                <>
                <SidebarDrawer
                    placement="left"
                    onClose={onClose}
                    visible={open}
                    width={320}
                    closable={true}
                    className="hideOnDesktop drawerSider"
                    style={{borderRadius:'0 10px 10px 0'}}
                    >
                    {children}
                </SidebarDrawer>
                <StickyButton style={{
                        top:0, 
                        position: "sticky",
                        borderRadius: '0px 110% 110% 0px',
                        padding: '6px 14px 6px 8px',
                        width: '48px',
                        height: '48px',
                        lineHeight: '48px',
                        marginRight: '-48px',
                        background: '#333333'
                    }} onClick={showDrawer}/>
                </>
                :
                <Sider
                className='drawerSider'
                    breakpoint="sm"
                    collapsedWidth="0"
                    width={320}
                    style={{
                        background: "white",
                        position: "sticky",
                        top: 0,
                        height: `calc(100vh - ${headerHeight})`,
                    }}
                    >{children}
                </Sider> 
            }
            </React.Fragment>
        );
}
export default RespSidebar;